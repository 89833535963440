import { ReactElement, useEffect, useState } from 'react';
import { useProjectConfig, useProjectConfigDraft } from '../../ContextProviders/AppContext';
import { PageWithSidebar } from '../PageTypes';
import { ErrorPage } from '../Error/ErrorPage';
import { Loading } from '../Loading/Loading';
import { configActions } from '../../../Hooks/DatabaseActions';
import { useFirestore } from '../../ContextProviders/Firebase';
import { useConcreteProject } from '../../ContextProviders/ProjectContext';
import { SettingsSidebar } from './SettingsSidebar';
import { Input, InputGroup, Label } from 'reactstrap';
import { toasts } from '../../../shared';
import { faSave, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { IconButton } from '../../Buttons/Buttons';
import { useLocalization } from '../../ContextProviders/LocalizationContext';

export const ProjectDataManagement = (): ReactElement => {
  const localization = useLocalization();
  const { doc: config, error, loading } = useProjectConfigDraft();
  const firebase = useFirestore();
  const project = useConcreteProject();
  const [name, setName] = useState(config.name || '');
  const [organization, setOrganization] = useState(config.organization || '');
  const [publisher, setPublisher] = useState(config.publisher || '');
  const { update: updateConfig } = configActions(firebase, project.id);

  const projectConfig = useProjectConfig();
  useEffect(() => {
    document.title = `${localization.strings.settings.projectSettings} | ${projectConfig.doc.name}`;
  }, [projectConfig.doc.name, localization.strings.settings.projectSettings]);

  useEffect(() => {
    setName(config.name || '');
    setOrganization(config.organization || '');
    setPublisher(config.publisher || '');
    // eslint-disable-next-line
  }, [loading]);

  if (error) return <ErrorPage error={error} />;
  if (loading) return <Loading waitingFor="Project Configuration" />;

  const save = () => {
    updateConfig({ name, organization, publisher })
      .then(() => {
        toasts.success(localization.strings.settings.updateOk);
      })
      .catch(() => {
        toasts.error(localization.strings.settings.updateFailed);
      });
  };
  const cancel = () => {
    setName(config.name || '');
    setOrganization(config.organization || '');
    setPublisher(config.publisher || '');
  };

  return (
    <PageWithSidebar>
      <SettingsSidebar />
      <main className="padded-container">
        <div className="settings-page">
          <h1>{localization.strings.settings.projectSettings}</h1>
          <hr />
          <form
            onSubmit={(e) => {
              save();
              e.preventDefault();
            }}
          >
            <div className="acc-mng-module shadow-sm">
              <Label>{localization.strings.settings.projectNameHint}</Label>
              <InputGroup>
                <Input type="text" value={name} onChange={(e) => setName(e.target.value)} />
              </InputGroup>
              <Label>{localization.strings.settings.projectOrganisationHint}</Label>
              <InputGroup>
                <Input type="text" value={organization} onChange={(e) => setOrganization(e.target.value)} />
              </InputGroup>
              <Label>{localization.strings.settings.projectPublisherHint}</Label>
              <InputGroup>
                <Input type="text" value={publisher} onChange={(e) => setPublisher(e.target.value)} />
              </InputGroup>
              <div style={{ marginTop: '0.5rem' }}>
                <IconButton theme="dark" onClick={save} icon={faSave} text={localization.strings.global.save} />
                <IconButton theme="dark" onClick={cancel} icon={faTimes} text={localization.strings.global.clear} />
              </div>
            </div>
          </form>
        </div>
      </main>
    </PageWithSidebar>
  );
};
