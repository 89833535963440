import { ReactElement, useEffect } from 'react';
import { useProjectConfig } from '../../ContextProviders/AppContext';
import { useLocalization } from '../../ContextProviders/LocalizationContext';

export const ErrorPage = ({ error }: { error: Error }): ReactElement => {
  const localization = useLocalization();
  const projectConfig = useProjectConfig();
  useEffect(() => {
    document.title = `${localization.strings.error.error} | ${projectConfig.doc.name}`;
  }, [projectConfig.doc.name, localization.strings.error.error]);

  return <code>{error.toString()}</code>;
};
