import { DragDropContext, Draggable, Droppable, DropResult, HookProvided } from 'react-beautiful-dnd';
import {
  useArticle,
  useCatDataLoaded,
  useCategory,
  useCategoryTree,
  useSection,
} from '../../ContextProviders/AppContext';
import { Section, Article, Category, IsChief, IsChangelog } from '../../../Types';
import { createContext, CSSProperties, ReactElement, useContext, useEffect, useRef, useState } from 'react';
import { Input, ButtonGroup, Button, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronRight,
  faEdit,
  faFolderPlus,
  faPlus,
  faSave,
  faTimes,
  faTrash,
  faUndoAlt,
} from '@fortawesome/pro-solid-svg-icons';
import { toasts } from '../../../shared';
import { normalize } from './treeUtil';
import './CategoryNavEdit.scss';
import { useFirestore } from '../../ContextProviders/Firebase';
import { useConcreteProject } from '../../ContextProviders/ProjectContext';
import { useAuth } from '../../ContextProviders/Auth';
import { CategoryTree } from '../../../util/DataHelpers';
import { IconButton } from '../../Buttons/Buttons';
import {
  changelogCategoryTreeFunctions,
  chiefCategoryTreeFunctions,
  standardCategoryTreeFunctions,
  TreeFunctions,
} from './treeFunctions';
import { categoryActions, changelogCategoryActions, chiefCategoryActions } from '../../../Hooks/DatabaseActions';
import { Link, useHistory, useParams } from 'react-router-dom';
import Collapsible from 'react-collapsible';
import { useStyle } from '../../ContextProviders/Style';
import _ from 'lodash';
import { useLocalization } from '../../ContextProviders/LocalizationContext';

type ArticleGrabContextProps = [boolean, React.Dispatch<React.SetStateAction<boolean>>];
const ArticleGrabContext = createContext<ArticleGrabContextProps>([
  false,
  () => {
    /**/
  },
]);

interface WithArticles {
  articles: Article[];
}

export type CategoryTreeNorm = Category & {
  sections: (Section & {
    articles: Article[];
  })[];
};

interface DraggableArticleProps {
  article: Article & IsChief & IsChangelog;
  parentDeleted?: boolean;
  functions: TreeFunctions;
}

const DraggableArticle = ({ article: tArticle, functions, parentDeleted = false }: DraggableArticleProps) => {
  const localization = useLocalization();
  const currentArticle = useArticle(tArticle.fId);
  const article = { ...tArticle, deleted: currentArticle?.deleted || false };
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
  const params = useParams<{ articleId: string }>();
  const auth = useAuth();

  const edtaibleItemProps = {
    save: (value) => {
      void functions.editArticle(article, value, auth.displayName);
    },
    remove: () => {
      // Check can only be false for non-chief and non-changelog articles: removes "marked for deletion" state with no modal
      if (!article.deleted) setIsConfirmingDelete(true);
      else void functions.removeArticle(article, auth.displayName);
    },
    value: article.name,
    placeHolder: localization.strings.forms.placeHolders.articleName,
    styleNoEdit: { paddingLeft: '1rem' },
    styleEdit: { paddingLeft: '1rem' },
    canEdit: !article.deleted,
    canDelete: !parentDeleted,
    isDeleted: article.deleted,
  };

  //When publishing and an article/section vanishes -> Remove it
  if (!currentArticle && functions.syncVanishedArticle) {
    void functions.syncVanishedArticle(tArticle);
  }

  const classes = [
    'navable-article',
    article.deleted || currentArticle?.deleted ? 'article-deleted' : undefined,
    article.fId === params.articleId ? 'article-active' : undefined,
  ]
    .filter((c) => c !== undefined)
    .join(' ');

  return (
    <>
      <Draggable draggableId={article.fId} index={article.orderIndex}>
        {(provided, snapshot) => {
          return (
            <div
              // eslint-disable-next-line
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              className={classes}
            >
              <Link to={`/category/${article.category}/${article.fId}`}>
                <EditableItem {...edtaibleItemProps} />
              </Link>
            </div>
          );
        }}
      </Draggable>
      <Modal isOpen={isConfirmingDelete}>
        <ModalHeader>{localization.strings.article.deleteModalHeader}</ModalHeader>
        <ModalBody>
          {article.isChief || article.isChangelog
            ? localization.strings.article.deleteModalBodyPerma
            : localization.strings.article.deleteModalBody}
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              setIsConfirmingDelete(false);
              void functions.removeArticle(article, auth.displayName);
            }}
            color="danger"
          >
            {localization.strings.global.delete}
          </Button>
          <Button onClick={() => setIsConfirmingDelete(false)}>{localization.strings.global.cancel}</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

interface DraggableSectionProps {
  section: Section & WithArticles & IsChief & IsChangelog;
  functions: TreeFunctions;
}
const DraggableSection = ({ functions, section: tSection }: DraggableSectionProps) => {
  const localization = useLocalization();
  const currentSection = useSection(tSection.fId);
  const section = { ...tSection, deleted: currentSection?.deleted || false };
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
  const [open, setOpen] = useState<boolean>(!(useConcreteProject().collapseSectionsByDefault || false));
  const timeoutOpenMouseOver = useRef(0);
  const [grabbed] = useContext(ArticleGrabContext);
  const appStyle = useStyle();
  const auth = useAuth();

  //When publishing and an article/section vanishes -> Remove it
  if (!currentSection && functions.syncVanishedSection && section.fId !== 'DEFAULT_SECTION') {
    void functions.syncVanishedSection(tSection);
  }

  const edtaibleItemProps = {
    save: (value) => {
      void functions.editSection(section, value);
    },
    remove: () => {
      // Check can only be false for non-chief and non-changelog articles: removes "marked for deletion" state with no modal
      if (!section.deleted) setIsConfirmingDelete(true);
      else void functions.removeSection(section);
    },
    editable: section.fId !== 'DEFAULT_SECTION',
    value: section.name,
    style: { fontWeight: 'bold' as const, color: 'var(--color-primary-dark)' },
    styleNoEdit: { paddingLeft: '1rem', width: '18rem' },
    styleEdit: { paddingLeft: '1rem' },
    isDeleted: section.deleted,
  };

  const onMouseOverHandle = () => {
    if (grabbed)
      timeoutOpenMouseOver.current = (setTimeout(() => {
        setOpen(true);
      }, 500) as unknown) as number;
  };
  const onMouseOutHandle = () => {
    clearTimeout(timeoutOpenMouseOver.current);
  };

  return (
    <div className="section-title" onMouseOver={onMouseOverHandle} onMouseOut={onMouseOutHandle}>
      <Draggable
        isDragDisabled={section.fId === 'DEFAULT_SECTION'}
        draggableId={section.fId}
        index={section.orderIndex}
      >
        {(provided, snapshot) => (
          <div
            // eslint-disable-next-line
            ref={provided.innerRef}
            {...provided.draggableProps}
            className={`draggable-section ${section.deleted ? 'section-deleted' : ''}`}
          >
            <div
              {...provided.dragHandleProps}
              onClick={() => setOpen((o) => !o)}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
            >
              <EditableItem {...{ ...edtaibleItemProps }} canEdit={!section.deleted} />
              <FontAwesomeIcon
                icon={open ? faChevronDown : faChevronRight}
                size="1x"
                color={appStyle.primaryDark}
                style={{ marginRight: open ? '0.6rem' : '0.7rem', cursor: 'pointer' }}
              />
            </div>
            <Collapsible open={open} trigger={<></>} triggerDisabled={true} transitionTime={150}>
              {!section.deleted && (
                <ArticleAdd
                  addArticle={(name: string) => {
                    return functions.addArticle(name, section.fId, auth.displayName);
                  }}
                  onCancel={() => {
                    /** */
                  }}
                />
              )}
              <Droppable isDropDisabled={!open} droppableId={section.fId} type="section" direction="vertical">
                {(provided, snapshot) => {
                  return (
                    <div
                      {...provided.droppableProps}
                      // eslint-disable-next-line
                      ref={provided.innerRef}
                    >
                      {section.articles.map((a) => (
                        <DraggableArticle
                          article={a}
                          functions={functions}
                          key={a.fId}
                          parentDeleted={section.deleted}
                        />
                      ))}
                      {provided.placeholder}
                    </div>
                  );
                }}
              </Droppable>
            </Collapsible>
          </div>
        )}
      </Draggable>

      <Modal isOpen={isConfirmingDelete}>
        <ModalHeader>{localization.strings.section.deleteModalHeader}</ModalHeader>
        <ModalBody>
          {section.isChief || section.isChangelog
            ? localization.strings.section.deleteModalBodyPerma
            : localization.strings.section.deleteModalBody}
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              setIsConfirmingDelete(false);
              void functions.removeSection(section);
            }}
            color="danger"
          >
            {localization.strings.global.delete}
          </Button>
          <Button onClick={() => setIsConfirmingDelete(false)}>{localization.strings.global.cancel}</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

const getNormedTree = (tree: CategoryTree | undefined, name: string): CategoryTreeNorm | undefined => {
  return tree === undefined
    ? undefined
    : normalize({
        ...tree,
        sections: [
          ...tree.sections,
          {
            fId: 'DEFAULT_SECTION',
            category: tree.fId,
            name,
            orderIndex: tree.sections.length + 1,
            articles: tree.defaultSection,
          },
        ],
      });
};

export const CategoryNavEdit = ({ id }: { id: string }) => {
  const localization = useLocalization();
  const history = useHistory();
  const [isDirty, setIsDirty] = useState(history.location.state?.categoryNavEdit?.isDirty || false);
  const category = useCategory(id);
  const tree = useCategoryTree(id);
  const [grabbed, setGrabbed] = useState(false);

  const [treeState, setTreeState] = useState<CategoryTreeNorm | undefined>(
    history.location.state?.categoryNavEdit?.treeState ||
      getNormedTree(tree, localization.strings.section.defaultSection),
  );
  const firestore = useFirestore();
  const project = useConcreteProject();
  const auth = useAuth();

  const loaded = useCatDataLoaded();
  const [stopUpdates, setStopUpdates] = useState(false);

  useEffect(() => {
    //Make sure to get the full data, then set it
    if (!isDirty && loaded && !stopUpdates) {
      setStopUpdates(true);
      const normedTree = getNormedTree(tree, localization.strings.section.defaultSection);
      setTreeState((treeState) => (_.isEqual(treeState, normedTree) ? treeState : normedTree));
    }
  }, [tree, isDirty, loaded, stopUpdates, localization.strings.section.defaultSection]);

  const save = () => {
    if (!category || !firestore || !project || !treeState) {
      toasts.error(localization.strings.error.error);
      return;
    }
    if (category?.isChangelog) {
      changelogCategoryActions(firestore, project.id)
        .sort(treeState)
        .then(() => {
          toasts.success(localization.strings.category.updated);
          setIsDirty(false);
        })
        .catch(() => {
          toasts.error(localization.strings.category.updateError);
        });
    } else if (category?.isChief) {
      chiefCategoryActions(firestore, project.id)
        .sort(treeState)
        .then(() => {
          toasts.success(localization.strings.category.updated);
          setIsDirty(false);
        })
        .catch(() => {
          toasts.error(localization.strings.category.updateError);
        });
    } else if (category) {
      categoryActions(firestore, project.id)
        .sort(treeState)
        .then(() => {
          toasts.success(localization.strings.category.updated);
          setIsDirty(false);
        })
        .catch(() => {
          toasts.error(localization.strings.category.updateError);
        });
    } else {
      toasts.error('Unknown category');
    }
  };

  const saveRef = useRef(save);
  saveRef.current = save;

  if (!treeState || !auth || !auth.user) return <></>;

  const onDragStart = () => {
    setGrabbed(true);
  };
  const onDragEnd = (result: DropResult, provided: HookProvided) => {
    setGrabbed(false);
    setTreeState((treeState) => {
      if (!treeState) return treeState;

      const dest = result.destination;

      if (dest === undefined || dest === null) return treeState;

      if (result.type === 'category') {
        if (dest.index >= treeState.sections.length - 1) dest.index--;

        if (result.draggableId === 'DEFAULT_SECTION') return treeState;
        setIsDirty(true);
        return {
          ...treeState,
          sections: treeState.sections //re-sort the list with the new index of the section
            .slice()
            .sort((s1, s2) => s1.orderIndex - s2.orderIndex) //Sort by orderindex
            .map((s, i) => ({ ...s, orderIndex: i })) //normalize
            .map((s) =>
              s.fId === result.draggableId
                ? { ...s, orderIndex: dest.index + (s.orderIndex < dest.index ? 0.5 : -0.5) }
                : s,
            ) //Insert at correct location
            .sort((s1, s2) => s1.orderIndex - s2.orderIndex) //sort
            .map((s, i) => ({ ...s, orderIndex: i })), //normalize
        };
      } else if (result.type === 'section') {
        setIsDirty(true);
        let popped: Article | undefined;
        //Find the item to remove, stash it
        treeState.sections.forEach((s) => {
          s.articles.forEach((a) => {
            if (a.fId === result.draggableId) popped = a;
          });
        });
        return {
          ...treeState,
          sections: treeState.sections.map((s) => {
            if (s.fId === result.source.droppableId && s.fId === dest.droppableId) {
              //changing order in same section, do something similar to above
              return {
                ...s,
                articles: s.articles
                  .slice()
                  .sort((a1, a2) => a1.orderIndex - a2.orderIndex)
                  .map((a, i) => ({ ...a, orderIndex: i }))
                  .map((a) =>
                    a.fId === result.draggableId
                      ? { ...a, orderIndex: dest.index + (a.orderIndex < dest.index ? 0.5 : -0.5) }
                      : a,
                  )
                  .sort((a1, a2) => a1.orderIndex - a2.orderIndex)
                  .map((a, i) => ({ ...a, orderIndex: i })),
              };
            } else {
              //source section and target section differs
              if (s.fId === result.source.droppableId) {
                //source, remove item
                return {
                  ...s,
                  articles: s.articles
                    .slice()
                    .sort((a1, a2) => a1.orderIndex - a2.orderIndex)
                    .map((a, i) => ({ ...a, orderIndex: i }))
                    .filter((a) => a.fId !== result.draggableId)
                    .sort((a1, a2) => a1.orderIndex - a2.orderIndex)
                    .map((a, i) => ({ ...a, orderIndex: i })),
                };
              }
              if (s.fId === dest.droppableId) {
                //dest, insert item
                if (popped === undefined) throw new Error("Couldn't find article being sorted");

                return {
                  ...s,
                  articles: [...s.articles, { ...popped, orderIndex: dest.index - 0.5 }]
                    .slice()
                    .sort((a1, a2) => a1.orderIndex - a2.orderIndex)
                    .map((a, i) => ({ ...a, orderIndex: i })),
                };
              }
              //do nothing
              return s;
            }
          }),
        };
      }
      return treeState;
    });
  };

  const functions = category?.isChief
    ? chiefCategoryTreeFunctions(firestore, project.id, category.fId || '', auth, setTreeState)
    : category?.isChangelog
    ? changelogCategoryTreeFunctions(firestore, project.id, category.fId || '', auth, setTreeState)
    : standardCategoryTreeFunctions(firestore, project.id, category?.fId || '', auth, setTreeState);

  const cancel = () => {
    setTreeState(getNormedTree(tree, localization.strings.section.defaultSection));
    setIsDirty(false);
  };

  return (
    <div style={{ width: '100%' }}>
      <ArticleGrabContext.Provider value={[grabbed, setGrabbed]}>
        <CategorySave
          save={() => {
            save();
          }}
          cancel={() => {
            cancel();
          }}
          isDirty={isDirty}
        />
        <SectionAdd add={functions.addSection} />
        <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
          <Droppable droppableId="droppable" type="category" direction="vertical">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                // eslint-disable-next-line
                ref={provided.innerRef}
                className="draggable-section"
              >
                {treeState.sections.map((item, index) => (
                  <DraggableSection functions={functions} section={item} key={item.fId} />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </ArticleGrabContext.Provider>
    </div>
  );
};

const CategorySave = ({ save, cancel, isDirty }: { save: () => void; cancel: () => void; isDirty: boolean }) => {
  const localization = useLocalization();
  return (
    <Modal wrapClassName="bottom-modal-noblock" size="sm" trapFocus={false} backdrop={false} isOpen={isDirty}>
      <ModalBody>
        {localization.strings.sorting.unsavedChanges}
        <div className="save-or-cancel">
          <IconButton onClick={save} icon={faSave} text={localization.strings.sorting.saveSorting} theme={'dark'} />
          <IconButton
            onClick={cancel}
            icon={faTimes}
            text={localization.strings.sorting.cancelSorting}
            theme={'dark'}
          />
        </div>
      </ModalBody>
    </Modal>
  );
};

interface EditableTitleProps {
  value: string;
  save?: (value: string) => void;
  cancel?: () => void;
  remove?: () => void;
  placeHolder?: string;
  editable?: boolean;
  style?: CSSProperties;
  styleNoEdit?: CSSProperties;
  styleEdit?: CSSProperties;
  editing?: boolean;
  canEdit?: boolean;
  canDelete?: boolean;
  isDeleted?: boolean;
  confirmDelete?: boolean;
  valueViewer?: (value: string) => ReactElement;
}
const EditableItem = ({
  valueViewer = (value: string) => <>{value}</>,
  value,
  style = {},
  styleNoEdit = {},
  styleEdit = {},
  save = () => {
    /**/
  },
  remove = () => {
    /**/
  },
  editable = true,
  cancel = () => {
    /**/
  },
  placeHolder = '',
  editing = false,
  canDelete = true,
  canEdit = true,
  isDeleted = false,
}: EditableTitleProps) => {
  const [isEditing, setIsEditing] = useState(editing);
  const [eValue, setEValue] = useState(value);

  const lSave = (e) => {
    e.preventDefault();
    save(eValue);
    setIsEditing(false);
  };
  const lCancel = () => {
    cancel();
    setEValue(value);
    setIsEditing(false);
  };

  if (isEditing)
    return (
      <form
        onSubmit={(e) => lSave(e)}
        style={{ height: '3rem', paddingLeft: '1rem', ...style, ...styleEdit }}
        className="d-flex justify-content-between align-items-center"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <Input
          type="text"
          id="section"
          placeholder={placeHolder}
          value={eValue}
          onChange={({ target: { value } }) => setEValue(value)}
          autoFocus
        />
        <ButtonGroup>
          <Button type="submit" onClick={(e) => lSave(e)} style={{ marginLeft: '0.2rem' }}>
            <FontAwesomeIcon icon={faSave} />
          </Button>
          <Button onClick={() => lCancel()}>
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </ButtonGroup>
      </form>
    );
  else
    return (
      <div style={{ ...style, ...styleNoEdit, display: 'flex', alignItems: 'center' }} className="editable-item">
        <div className="article-name-truncate">{valueViewer(value)}</div>
        {editable && (
          <ButtonGroup>
            {canEdit && (
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setIsEditing(true);
                }}
                style={{ background: 'none', border: 'none', color: 'var(--color-primary)' }}
              >
                <FontAwesomeIcon icon={faEdit} />
              </Button>
            )}
            {canDelete && (
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  remove();
                }}
                style={{ background: 'none', border: 'none', color: 'var(--color-primary)' }}
              >
                <FontAwesomeIcon icon={isDeleted ? faUndoAlt : faTrash} />
              </Button>
            )}
          </ButtonGroup>
        )}
      </div>
    );
};

const SectionAdd = ({ add }: { add: (name: string) => void }) => {
  const localization = useLocalization();
  const [addIconPressed, setAddIconPressed] = useState(false);
  const addE = (value: string) => {
    const isValid = value.trim() !== '' && value.length < 120;
    if (!isValid) {
      toasts.error(localization.strings.section.wrongSectionName);
      cancel();
      return;
    }
    setAddIconPressed(false);
    add(value.trim());
  };
  const cancel = () => {
    setAddIconPressed(false);
  };
  if (addIconPressed) {
    return (
      <div className="section-adder">
        <EditableItem
          value={''}
          editing={true}
          save={(value: string) => {
            addE(value);
          }}
          cancel={() => {
            cancel();
          }}
          placeHolder={localization.strings.forms.placeHolders.sectionName}
          styleEdit={{ paddingLeft: 0 }}
        />
      </div>
    );
  }
  return (
    <div className="section-adder">
      <IconButton
        onClick={() => setAddIconPressed(true)}
        icon={faFolderPlus}
        text={localization.strings.section.addNewSection}
        theme="dark"
      />
    </div>
  );
};

interface ArticleAddProps {
  addArticle: (name: string) => Promise<void>;
  onCancel: () => void;
}
const ArticleAdd = ({ addArticle, onCancel }: ArticleAddProps) => {
  const localization = useLocalization();
  const [isAdding, setIsAdding] = useState(false);
  const addA = (name: string) => {
    const trimmedName = name.trim();
    const isValid = trimmedName !== '' && trimmedName.length < 120;
    if (!isValid) {
      toasts.error(localization.strings.article.wrongArticleName);
      cancel();
    } else {
      void addArticle(trimmedName);
      cancel();
    }
  };
  const cancel = () => {
    onCancel();
    setIsAdding(false);
  };

  if (isAdding)
    return (
      <EditableItem
        value={''}
        editing={true}
        save={(value: string) => {
          void addA(value);
        }}
        cancel={() => {
          cancel();
        }}
        placeHolder={localization.strings.forms.placeHolders.articleName}
      />
    );

  return (
    <div className="article-adder">
      <IconButton
        onClick={() => setIsAdding(true)}
        icon={faPlus}
        text={localization.strings.article.addNewArticle}
        theme="dark"
      />
    </div>
  );
};
