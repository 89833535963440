import { Dispatch, ReactElement, SetStateAction, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Collapse, Input, Label, ListGroup, ListGroupItem, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import {
  useArticlesDraft,
  useCategoriesDraft,
  useProjectConfig,
  usePublishState,
} from '../../ContextProviders/AppContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Publish.scss';
import {
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faQuestionCircle,
  faUpload,
} from '@fortawesome/pro-solid-svg-icons';
import { IconButton } from '../../Buttons/Buttons';
import { toasts } from '../../../shared';
import { appActions } from '../../../Hooks/DatabaseActions';
import { useAuth } from '../../ContextProviders/Auth';
import { PublishState, UserRole } from '@eir/core';
import { useFirestore } from '../../ContextProviders/Firebase';
import { useConcreteProject } from '../../ContextProviders/ProjectContext';
import { useCollection } from '../../../Hooks';
import { CMSUser } from '../../../Types';
import { useLocalization } from '../../ContextProviders/LocalizationContext';

type Order = 'asc' | 'desc';
type Attribute = 'name' | 'state' | 'lastUpdatedBy' | 'lastUpdated';
type SortOptions = {
  order: Order;
  attribute: Attribute;
};

type SortButtonProps = {
  attribute: Attribute;
  disabled: boolean;
  sortOptions: SortOptions;
  handleClick: (attribute: Attribute) => void;
};
const SortButton = ({ attribute, disabled, sortOptions, handleClick }: SortButtonProps): ReactElement => {
  const localization = useLocalization();
  const { titles } = localization.strings.publish;
  const title = attribute === 'lastUpdated' ? titles['lastUpdatedDate'] : titles[attribute];

  return (
    <Col onClick={() => handleClick(attribute)} className="sort-button" disabled={disabled}>
      {title}
      {!disabled && (
        <FontAwesomeIcon
          icon={sortOptions.attribute === attribute && sortOptions.order === 'desc' ? faAngleUp : faAngleDown}
          className={`sort-indicator ${sortOptions.attribute === attribute && 'active'}`}
        ></FontAwesomeIcon>
      )}
    </Col>
  );
};

type ChangedArticlesListProps = {
  selectedIds: string[];
  setSelectedIds: (ids: string[]) => void;
};
const ChangedArticlesList = ({ selectedIds, setSelectedIds }: ChangedArticlesListProps): ReactElement => {
  const localization = useLocalization();
  const articles = useArticlesDraft().docs.filter((a) => a.state);
  const categories = useCategoriesDraft().docs;
  const [checkAll, setCheckAll] = useState(false);
  const [sortedArticles, setSortedArticles] = useState([...articles]);
  const [sortOptions, setSortOptions] = useState<SortOptions>({ attribute: 'lastUpdated', order: 'desc' });
  const projectConfig = useProjectConfig();
  const [publishState, , ,] = usePublishState();

  const doSort = (options: SortOptions) => {
    const { attribute, order } = options;
    const sortedData = [...articles].sort((a, b) => {
      let valueA = (a[attribute] || '').toString();
      let valueB = (b[attribute] || '').toString();

      if (attribute === 'state') {
        // We need to translate first, else the sorting doesn't seem correct for the user
        const { editStates } = localization.strings.publish;
        valueA = editStates[a[attribute] || ''].toString();
        valueB = editStates[b[attribute] || ''].toString();
      }

      return valueA.localeCompare(valueB, { numeric: true }) * (order === 'asc' ? 1 : -1);
    });
    setSortedArticles(sortedData);
  };

  const handleSortClick = (attribute: Attribute) => {
    if (sortOptions.attribute === attribute) {
      setSortOptions({ attribute, order: sortOptions.order === 'asc' ? 'desc' : 'asc' });
    } else {
      setSortOptions({ attribute, order: 'asc' });
    }
  };

  const handleCheckClick = (fId: string) => {
    if (publishState === PublishState.IN_FLIGHT) return;

    if (selectedIds.includes(fId)) setSelectedIds(selectedIds.filter((i) => i !== fId));
    else setSelectedIds([...selectedIds, fId]);
  };

  const handleCheckAllClick = () => {
    if (publishState === PublishState.IN_FLIGHT) return;

    if (!checkAll) {
      setCheckAll(true);
      setSelectedIds(sortedArticles.map((a) => a.fId));
    } else {
      setCheckAll(false);
      setSelectedIds([]);
    }
  };

  useEffect(() => {
    document.title = `${localization.strings.publish.publish} | ${projectConfig.doc.name}`;
  }, [projectConfig.doc.name, localization.strings.publish.publish]);

  useEffect(() => {
    if (articles.length !== sortedArticles.length) setCheckAll(false);
    doSort(sortOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articles.length, sortOptions]);

  useEffect(() => {
    if (checkAll && sortedArticles.length !== selectedIds.length) {
      setCheckAll(false);
    }
    if (sortedArticles.length === selectedIds.length && !checkAll) {
      setCheckAll(true);
    }
  }, [checkAll, selectedIds.length, sortedArticles.length]);

  return (
    <div className="publish-page">
      <div className="page-content">
        <div className="changes-table">
          <Row className="header">
            <Col className="check-col" onClick={handleCheckAllClick}>
              <Input
                type="checkbox"
                checked={checkAll}
                readOnly
                title={localization.strings.global.selectAll}
                disabled={sortedArticles.length === 0 || publishState === PublishState.IN_FLIGHT}
              />
            </Col>
            <Col className="grid-fix">
              <SortButton
                disabled={sortedArticles.length === 0 || publishState === PublishState.IN_FLIGHT}
                attribute="name"
                sortOptions={sortOptions}
                handleClick={handleSortClick}
              />
              <SortButton
                disabled={sortedArticles.length === 0 || publishState === PublishState.IN_FLIGHT}
                attribute="state"
                sortOptions={sortOptions}
                handleClick={handleSortClick}
              />
              <SortButton
                disabled={sortedArticles.length === 0 || publishState === PublishState.IN_FLIGHT}
                attribute="lastUpdatedBy"
                sortOptions={sortOptions}
                handleClick={handleSortClick}
              />
              <SortButton
                disabled={sortedArticles.length === 0 || publishState === PublishState.IN_FLIGHT}
                attribute="lastUpdated"
                sortOptions={sortOptions}
                handleClick={handleSortClick}
              />
            </Col>
          </Row>

          <div className="scrollable">
            {sortedArticles.length === 0 ? (
              <Row className="empty-list-item">{localization.strings.publish.noArticlesHaveBeenEdited}</Row>
            ) : (
              sortedArticles.map((a, index) => (
                <Row key={`${a.fId}${index}`} onClick={() => handleCheckClick(a.fId)}>
                  <Col className="check-col">
                    <Input
                      type="checkbox"
                      readOnly
                      id={a.fId}
                      checked={selectedIds.includes(a.fId)}
                      disabled={publishState === PublishState.IN_FLIGHT}
                    />
                  </Col>
                  <Col className="grid-fix">
                    <Col>
                      <Link to={`/articles/${a.fId}`} className="article-link">
                        <div>{a.name}</div>
                        <div className="sub">{categories.find((c) => c.fId === a.category)?.name}</div>
                      </Link>
                    </Col>
                    <Col>
                      <div className="v-center">{localization.strings.publish.editStates[a.state || '']}</div>
                    </Col>
                    <Col>
                      <div className="v-center">{a.lastUpdatedBy || ' '}</div>
                    </Col>
                    <Col>
                      {a.lastUpdated && (
                        <div>
                          <div>{`${a.lastUpdated.toDate().toLocaleDateString(['sv-SE'], {
                            weekday: 'short',
                            day: 'numeric',
                            month: 'numeric',
                            year: 'numeric',
                          })}`}</div>
                          <div className="sub">
                            {`${localization.strings.publish.atClock} ${a.lastUpdated
                              .toDate()
                              .toLocaleTimeString(['sv-SE'], { hour: '2-digit', minute: '2-digit' })}`}
                          </div>
                        </div>
                      )}
                    </Col>
                  </Col>
                </Row>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

type SidebarProps = {
  selectedIds: string[];
  setHelpModalIsOpen: Dispatch<SetStateAction<boolean>>;
};
const Sidebar = ({ selectedIds: selectedArticleIds, setHelpModalIsOpen }: SidebarProps): ReactElement => {
  const localization = useLocalization();
  const [publishMessage, setPublishMessage] = useState('');
  const [shouldSendNotification, setSendNotification] = useState(false);
  const [shouldNotifyAll, setShouldNotifyAll] = useState(true);
  const auth = useAuth();
  const firestore = useFirestore();
  const project = useConcreteProject();
  const aActions = appActions(firestore, project.id);
  const [publishState, setPublishState, , setPublishResult] = usePublishState();
  const [isVisible, setIsVisible] = useState(true);

  const [usersList, setUsersList] = useState<JSX.Element[]>([]);
  const { docs: CMSUsers, error: arError } = useCollection<CMSUser>('/cmsUser', {});
  const [selectedUsersList, setSelectedUsersList] = useState<string[]>([]);
  const getNewFalseFilledArrayFromCMSU = (): boolean[] =>
    new Array(CMSUsers.filter((ar) => ar.role === UserRole.STAFF).length).fill(false);
  // UI only state
  const [checkedUsersList, setCheckedUsersList] = useState<boolean[]>(getNewFalseFilledArrayFromCMSU());

  useEffect(() => {
    if (arError) {
      setUsersList([
        <ListGroupItem key="modalerror">{localization.strings.publish.errorLoadingAccounts}</ListGroupItem>,
      ]);
    } else if (CMSUsers.length > 0 && getNewFalseFilledArrayFromCMSU().length !== checkedUsersList.length) {
      // CMSUser list changed in length, re-make the users array. We ignore any other changes to the users list, like displayName changes.
      setCheckedUsersList(getNewFalseFilledArrayFromCMSU());
    } else {
      const ul = CMSUsers.filter((ar) => ar.role === UserRole.STAFF).map((ar, index) => (
        <ListGroupItem
          key={`${index}_${ar.displayName}`}
          onClick={() => {
            const checkedUL = [...checkedUsersList];

            if (selectedUsersList.includes(ar.fId)) {
              const selectedUL = [...selectedUsersList];
              selectedUL.splice(selectedUL.indexOf(ar.fId), 1);
              setSelectedUsersList([...selectedUL]);
              checkedUL[index] = false;
            } else {
              checkedUL[index] = true;
              setSelectedUsersList([...selectedUsersList, ar.fId]);
            }
            setCheckedUsersList(checkedUL);
          }}
          action={true}
          disabled={publishState === PublishState.IN_FLIGHT}
        >
          <Input type="checkbox" checked={checkedUsersList[index]} onChange={() => ''} />
          {ar.displayName}
        </ListGroupItem>
      ));
      if (ul.length === 0) {
        ul.push(
          <ListGroupItem key={'empty'} className="empty-list-item">
            {localization.strings.publish.noStaffAccountsExist}
          </ListGroupItem>,
        );
      }
      setUsersList(ul);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CMSUsers, arError, checkedUsersList, selectedUsersList]);

  const publish = () => {
    if (!auth.isAdmin || !auth.user?.uid) {
      toasts.error(localization.strings.auth.notAuthorized);
      return;
    }
    setPublishState(PublishState.IN_FLIGHT);
    toasts.info(localization.strings.publish.start);
    aActions
      .publish(
        auth.user.uid,
        publishMessage.trim().length > 0 ? publishMessage : localization.strings.publish.defaultPublishMessage,
        shouldSendNotification,
        shouldSendNotification ? shouldNotifyAll : false,
        shouldSendNotification ? (shouldNotifyAll ? [] : selectedUsersList) : [],
        selectedArticleIds,
      )
      .then((publishResult) => {
        if (publishResult.publishedSuccessful) {
          toasts.success(localization.strings.publish.publishOk);
          setPublishState(PublishState.IDLE);
          setPublishMessage('');
          return;
        }
        setPublishResult(publishResult);
        setPublishState(PublishState.ERROR);
      })
      .catch((e) => {
        toasts.error(localization.strings.publish.publishFail);
        setPublishState(PublishState.ERROR);
      });
  };

  return (
    <>
      <aside className={`publish-sidebar sidebar ${isVisible ? '' : 'closed'}`}>
        <div>
          <div className="p-3 pb-0">
            <h4 style={{ textAlign: 'center' }}>{localization.strings.publish.publish}</h4>
            <Input
              placeholder={localization.strings.publish.setPublishMessage}
              className="form-control"
              onChange={(e) => setPublishMessage(e.target.value)}
              value={publishMessage}
              disabled={publishState === PublishState.IN_FLIGHT}
            />

            <Label disabled={publishState === PublishState.IN_FLIGHT}>
              <Input
                type="checkbox"
                checked={shouldSendNotification}
                onChange={() => setSendNotification(!shouldSendNotification)}
                disabled={publishState === PublishState.IN_FLIGHT}
              />
              <span>&nbsp;{localization.strings.publish.publishWithNotification}</span>
            </Label>
          </div>

          <Collapse isOpen={shouldSendNotification}>
            <div className="targets p-3">
              <h5>{localization.strings.publish.notificationSettings}</h5>
              <Label style={{ marginBottom: 0 }} disabled={publishState === PublishState.IN_FLIGHT}>
                <Input
                  type="checkbox"
                  checked={shouldNotifyAll}
                  onChange={() => setShouldNotifyAll(!shouldNotifyAll)}
                  disabled={publishState === PublishState.IN_FLIGHT}
                />
                <span>&nbsp;{localization.strings.publish.notifyAll}</span>
              </Label>
              <Collapse isOpen={!shouldNotifyAll}>
                <div className="choose-targets p-2">
                  {localization.strings.publish.chooseTargets}
                  <ListGroup>{usersList}</ListGroup>
                </div>
              </Collapse>
            </div>
          </Collapse>

          <div className="p-3 pt-0">
            <IconButton
              icon={faUpload}
              text={
                publishState === PublishState.IN_FLIGHT
                  ? localization.strings.publish.publishing
                  : localization.strings.publish.publish
              }
              useCustomSpinner
              style={{ width: '100%', margin: '0' }}
              color="danger"
              isLoading={publishState === PublishState.IN_FLIGHT}
              onClick={publish}
            />
          </div>
        </div>

        <IconButton
          type="button"
          className="ml-4 publish-help"
          id="publishHelpButton"
          icon={faQuestionCircle}
          text={localization.strings.publish.help.title}
          onClick={() => setHelpModalIsOpen((open) => !open)}
        />
      </aside>

      <button className="collapse-button" onClick={() => setIsVisible(!isVisible)}>
        <FontAwesomeIcon icon={isVisible ? faAngleLeft : faAngleRight} />
      </button>
    </>
  );
};

type HelpModalProps = {
  isOpen: boolean;
  setHelpModalIsOpen: Dispatch<SetStateAction<boolean>>;
};
const HelpModal = ({ isOpen, setHelpModalIsOpen }: HelpModalProps): ReactElement => {
  const localization = useLocalization();

  return (
    <Modal isOpen={isOpen} toggle={() => setHelpModalIsOpen((open) => !open)} returnFocusAfterClose={false}>
      <ModalHeader toggle={() => setHelpModalIsOpen((open) => !open)}>
        {localization.strings.publish.publish} - {localization.strings.publish.help.title}
      </ModalHeader>
      <ModalBody>
        <h5>{localization.strings.publish.help.whatIsIncludedWhenPublishing.title}</h5>
        <p>
          {localization.strings.publish.help.whatIsIncludedWhenPublishing.articles}
          <ul>
            {localization.strings.publish.help.whatIsIncludedWhenPublishing.alwaysIncludedList.map((item, index) => (
              <li key={`${index}_${item}`}>{item}</li>
            ))}
          </ul>
        </p>
        <p>{localization.strings.publish.help.whatIsIncludedWhenPublishing.lastUpdatedBy}</p>
        <h5>{localization.strings.publish.help.sidebarSettings.title}</h5>
        <p>{localization.strings.publish.help.sidebarSettings.publishMessage}</p>
        <p>{localization.strings.publish.help.sidebarSettings.sendNotification}</p>
      </ModalBody>
    </Modal>
  );
};

export const Publish = (): ReactElement => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [helpModalIsOpen, setHelpModalIsOpen] = useState<boolean>(false);

  return (
    <div className="page-with-sidebar publish">
      <Sidebar selectedIds={selectedIds} setHelpModalIsOpen={setHelpModalIsOpen} />
      <ChangedArticlesList selectedIds={selectedIds} setSelectedIds={setSelectedIds} />
      <HelpModal isOpen={helpModalIsOpen} setHelpModalIsOpen={setHelpModalIsOpen} />
    </div>
  );
};
