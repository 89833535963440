import { createContext, ReactElement, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import useLocalStorage from 'react-use-localstorage';
import { FirestoreCollection, FirestoreDocument, useCollection, useDocument } from '../../Hooks/Database';
import {
  Image,
  Article,
  Platform,
  LegalDocuments,
  StaffAccount,
  Category,
  Section,
  Maybe,
  IsChief,
  IsDraft,
  IsChangelog,
  ProjectConfig,
  ExternalContent,
} from '../../Types';
import { CategoryTree, getCategoryTree, getByFId } from '../../util/DataHelpers';
import { useAuth } from './Auth';
import { useConcreteProject } from './ProjectContext';
import { permissionFilteredCategories } from '../../helpers';
import { useProtectedCollection } from '../../Hooks/useProtectedCollection';
import { PublishResultData, PublishState } from '@eir/core';
import { documentId } from 'firebase/firestore';

interface AppData {
  platform: FirestoreDocument<Platform>;
  legal: FirestoreDocument<LegalDocuments>;
  staff: FirestoreDocument<StaffAccount>;

  config: FirestoreDocument<ProjectConfig>;
  configDraft: FirestoreDocument<ProjectConfig>;

  articles: FirestoreCollection<Article & IsDraft & IsChief & IsChangelog>;
  articlesDraft: FirestoreCollection<Article & IsDraft & IsChief & IsChangelog>;
  chiefArticles: FirestoreCollection<Article & IsDraft & IsChief & IsChangelog>;

  sections: FirestoreCollection<Section & IsDraft & IsChief & IsChangelog>;
  sectionsDraft: FirestoreCollection<Section & IsDraft & IsChief & IsChangelog>;
  chiefSections: FirestoreCollection<Section & IsDraft & IsChief & IsChangelog>;

  categories: FirestoreCollection<Category & IsDraft & IsChief & IsChangelog>;
  categoriesDraft: FirestoreCollection<Category & IsDraft & IsChief & IsChangelog>;
  chiefCategories: FirestoreCollection<Category & IsDraft & IsChief & IsChangelog>;

  changelogArticles: FirestoreCollection<Article & IsDraft & IsChief & IsChangelog>;
  changelogSections: FirestoreCollection<Section & IsDraft & IsChief & IsChangelog>;

  images: FirestoreCollection<Image>;

  externals: FirestoreCollection<ExternalContent>;

  publishState: PublishState;
  setPublishState: (state: PublishState) => void;
  publishResult: PublishResultData | null;
  setPublishResult: (report: PublishResultData | null) => void;
  isDraftMode: boolean;
  setIsDraftMode: (isDraftMode: boolean) => void;
  fullscreen: boolean;
  setFullscreen: (fs: boolean) => void;
}

export const AppContext = createContext<AppData>({
  platform: { doc: {} as Platform, error: undefined, loading: true },
  legal: { doc: {} as LegalDocuments, error: undefined, loading: true },
  staff: { doc: {} as StaffAccount, error: undefined, loading: true },

  config: { doc: {} as ProjectConfig, error: undefined, loading: true },
  configDraft: { doc: {} as ProjectConfig, error: undefined, loading: true },

  articles: { docs: [], error: undefined, loading: true },
  articlesDraft: { docs: [], error: undefined, loading: true },
  chiefArticles: { docs: [], error: undefined, loading: true },

  sections: { docs: [], error: undefined, loading: true },
  sectionsDraft: { docs: [], error: undefined, loading: true },
  chiefSections: { docs: [], error: undefined, loading: true },

  categories: { docs: [], error: undefined, loading: true },
  categoriesDraft: { docs: [], error: undefined, loading: true },
  chiefCategories: { docs: [], error: undefined, loading: true },

  changelogArticles: { docs: [], error: undefined, loading: true },
  changelogSections: { docs: [], error: undefined, loading: true },

  images: { docs: [], error: undefined, loading: true },
  externals: { docs: [], error: undefined, loading: true },

  publishState: PublishState.IDLE,
  setPublishState: (state: PublishState) => {
    /**/
  },
  publishResult: null,
  setPublishResult: (report: PublishResultData | null) => {
    /**/
  },
  isDraftMode: false,
  setIsDraftMode: (isDraftMode: boolean) => {
    /**/
  },

  fullscreen: false,
  setFullscreen: (fs: boolean) => {
    /**/
  },
});

const CHANGELOG_CATEGORY = {
  name: 'Changelog',
  isProtected: false,
  isDraft: false,
  isChief: false,
  isChangelog: true,
  orderIndex: -1,
  fId: 'changelog',
};

export const useExternals = (): FirestoreCollection<ExternalContent> => useContext(AppContext).externals;
export const useExternal = (id?: string) => getByFId(id || '', useContext(AppContext).externals.docs);

export const usePlatform = (): FirestoreDocument<Platform> => useContext(AppContext).platform;
export const useLegal = (): FirestoreDocument<LegalDocuments> => useContext(AppContext).legal;
export const useStaff = (): FirestoreDocument<StaffAccount> => useContext(AppContext).staff;

export const useProjectConfig = (): FirestoreDocument<ProjectConfig> => useContext(AppContext).config;
export const useProjectConfigDraft = (): FirestoreDocument<ProjectConfig> => useContext(AppContext).configDraft;

//Articles
export const useArticles = (): FirestoreCollection<Article & IsDraft & IsChief & IsChangelog> => {
  const ctx = useContext(AppContext);
  const [isDraft] = useIsDraft();
  return isDraft ? ctx.articlesDraft : ctx.articles;
};
export const useArticlesPublished = (): FirestoreCollection<Article & IsDraft & IsChief & IsChangelog> =>
  useContext(AppContext).articles;
export const useArticlesDraft = (): FirestoreCollection<Article & IsDraft & IsChief & IsChangelog> =>
  useContext(AppContext).articlesDraft;
export const useChiefArticles = (): FirestoreCollection<Article & IsDraft & IsChief & IsChangelog> =>
  useContext(AppContext).chiefArticles;
export const useArticlesChangelog = (): FirestoreCollection<Article & IsDraft & IsChief & IsChangelog> =>
  useContext(AppContext).changelogArticles;

//Sections
export const useSections = (): FirestoreCollection<Section & IsDraft & IsChief & IsChangelog> => {
  const ctx = useContext(AppContext);
  const [isDraft] = useIsDraft();
  return isDraft ? ctx.sectionsDraft : ctx.sections;
};
export const useSectionsDraft = (): FirestoreCollection<Section & IsDraft & IsChief & IsChangelog> =>
  useContext(AppContext).sections;
export const useSectionsPublished = (): FirestoreCollection<Section & IsDraft & IsChief & IsChangelog> =>
  useContext(AppContext).sectionsDraft;
export const useChiefSections = (): FirestoreCollection<Section & IsDraft & IsChief & IsChangelog> =>
  useContext(AppContext).chiefSections;
export const useSectionsChangelog = (): FirestoreCollection<Section & IsDraft & IsChief & IsChangelog> =>
  useContext(AppContext).changelogSections;

//Categories
export const useCategories = (): FirestoreCollection<Category & IsDraft & IsChief & IsChangelog> =>
  useContext(AppContext).categories; //No draft as they are only needed in settings
export const useCategoriesPublished = (): FirestoreCollection<Category & IsDraft & IsChief & IsChangelog> =>
  useContext(AppContext).categories;
export const useCategoriesDraft = (): FirestoreCollection<Category & IsDraft & IsChief & IsChangelog> =>
  useContext(AppContext).categoriesDraft;
export const useChiefCategories = (): FirestoreCollection<Category & IsDraft & IsChief & IsChangelog> =>
  useContext(AppContext).chiefCategories;
//App mode
export const useIsDraft = (): [boolean, (d: boolean) => void] => {
  const auth = useAuth();
  return [useContext(AppContext).isDraftMode && auth.isAdmin, useContext(AppContext).setIsDraftMode];
};
export const usePublishState = (): [
  PublishState,
  (ps: PublishState) => void,
  PublishResultData | null,
  (crd: PublishResultData | null) => void,
] => [
  useContext(AppContext).publishState,
  useContext(AppContext).setPublishState,
  useContext(AppContext).publishResult,
  useContext(AppContext).setPublishResult,
];

export const useImages = (): FirestoreCollection<Image> => useContext(AppContext).images;

export const useFullscreen = (): [boolean, (d: boolean) => void] => {
  const ctx = useContext(AppContext);
  return [ctx.fullscreen, ctx.setFullscreen];
};

//Category tree convenience
export const useCategoryTree = (id: string): Maybe<CategoryTree> => {
  const categories = useCategories();
  const chiefCategories = useChiefCategories();

  const sections = useSections();
  const chiefSections = useChiefSections();
  const changelogSections = useSectionsChangelog();

  const articles = useArticles();
  const chiefArticles = useChiefArticles();
  const changelogArticles = useArticlesChangelog();

  return getCategoryTree(
    id,
    [...categories.docs, ...chiefCategories.docs], //Changelog is added as a regular category, though the articles reside in its own collection
    [...sections.docs, ...chiefSections.docs, ...changelogSections.docs],
    [...articles.docs, ...chiefArticles.docs, ...changelogArticles.docs],
  );
};

export const useArticle = (id: string | null | undefined): Maybe<Article & IsDraft & IsChief & IsChangelog> => {
  return getByFId(id || '', [...useArticles().docs, ...useChiefArticles().docs, ...useArticlesChangelog().docs]);
};
export const useSection = (id: string | null | undefined): Maybe<Section & IsDraft & IsChief & IsChangelog> => {
  return getByFId(id || '', [...useSections().docs, ...useChiefSections().docs, ...useSectionsChangelog().docs]);
};
export const useCategory = (id: string | null | undefined): Maybe<Category & IsDraft & IsChief & IsChangelog> => {
  const categories = useCategories().docs;
  const chiefCategories = useChiefCategories().docs;
  if (id === 'changelog') return CHANGELOG_CATEGORY;

  return getByFId(id || '', [...categories, ...chiefCategories]);
};

export const useCatDataLoaded = () => {
  return [
    useChiefArticles(),
    useChiefSections(),
    useArticlesChangelog(),
    useSectionsChangelog(),
    useCategories(),
    useChiefCategories(),
  ].every((item) => !item.loading);
};

export function AppDataProvider({ children }: { children?: ReactNode }): ReactElement {
  const project = useConcreteProject();
  const [localStorageDraft, setLocalStorageDraft] = useLocalStorage('draftMode', 'false');
  const [isDraftMode, setIsDraftMode] = useState(false);
  const [publishState, setPublishState] = useState(PublishState.IDLE);
  const [publishResult, setPublishResult] = useState<PublishResultData | null>(null);
  const auth = useAuth();
  const [fullscreenJSON, setFullscreenJSON] = useLocalStorage('fullscreen', 'false');

  useEffect(() => {
    setIsDraftMode(JSON.parse(localStorageDraft));
  }, [localStorageDraft]);

  const legal = useDocument<LegalDocuments>(`platform/legalDocuments`, {});
  const platform = useDocument<Platform>(`project/${project.id}`, {});
  const staff = useDocument<StaffAccount>(`project/${project.id}/resources/staffAccount`, {});
  const config = useDocument<ProjectConfig>(`project/${project.id}/configuration/published`, {});
  const categories = useCollection<Category, IsDraft & IsChief & IsChangelog>(`project/${project.id}/category`, {});
  const externalsUnfiltered = useCollection<ExternalContent>(`project/${project.id}/externals`, {});

  // eslint-disable-next-line
  const retryArticles = useMemo(() => ({}), [categories.docs, auth.categoryPermissions, auth.isAdmin]);
  // We need to get a list of externals, but with
  const allowedCategories = permissionFilteredCategories(
    auth.categoryPermissions,
    [...categories.docs, ...externalsUnfiltered.docs],
    auth.isAdmin,
  );

  const articles = useProtectedCollection<Article, IsDraft & IsChief & IsChangelog>(
    'article',
    'category',
    {},
    allowedCategories,
    retryArticles,
  );

  const articlesDraft = useCollection<Article, IsDraft & IsChief & IsChangelog>(
    auth.isAdmin ? `project/${project.id}/articleDraft` : undefined,
    { isDraft: true },
  );
  const chiefArticles = useCollection<Article, IsDraft & IsChief & IsChangelog>(
    auth.isAdmin ? `project/${project.id}/chief/db/article` : undefined,
    { isChief: true },
  );

  const sections = useCollection<Section, IsDraft & IsChief & IsChangelog>(`project/${project.id}/section`, {});
  const sectionsDraft = useCollection<Section, IsDraft & IsChief & IsChangelog>(
    auth.isAdmin ? `project/${project.id}/sectionDraft` : undefined,
    { isDraft: true },
  );
  const chiefSections = useCollection<Section, IsDraft & IsChief & IsChangelog>(
    auth.isAdmin ? `project/${project.id}/chief/db/section` : undefined,
    { isChief: true },
  );

  const categoriesDraft = useCollection<Category, IsDraft & IsChief & IsChangelog>(
    auth.isAdmin ? `project/${project.id}/categoryDraft` : undefined,
    { isDraft: true },
  );
  const chiefCategories = useCollection<Category, IsDraft & IsChief & IsChangelog>(
    auth.isAdmin ? `project/${project.id}/chief/db/category` : undefined,
    { isChief: true },
  );

  // eslint-disable-next-line
  const retryChangelog = useMemo(() => ({}), [auth.categoryPermissions, auth.isAdmin]);
  const changelogSections = useCollection<Section, IsDraft & IsChief & IsChangelog>(
    project.changeLogEnabled ? `project/${project.id}/changelog/db/section` : undefined,
    { isChangelog: true },
    retryChangelog,
  );
  const changelogArticles = useCollection<Article, IsDraft & IsChief & IsChangelog>(
    project.changeLogEnabled && allowedCategories.includes('changelog')
      ? `project/${project.id}/changelog/db/article`
      : undefined,
    { isChangelog: true },
    retryChangelog,
  );

  //Changelog has protection against getting it if it is protected, unlike other categories
  const images = useCollection<Image>(`project/${project.id}/uploadedImage`, {});

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const retryExternals = useMemo(() => ({}), [externalsUnfiltered.docs, auth.categoryPermissions, auth.isAdmin]);
  const externals = useProtectedCollection<ExternalContent>(
    'externals',
    documentId(),
    {},
    allowedCategories,
    retryExternals,
  );

  const configDraft = useDocument<ProjectConfig>(
    auth.isAdmin ? `project/${project.id}/configuration/draft` : undefined,
    {},
  );

  let fsParsed = false;
  try {
    const fsParsedTmp = JSON.parse(fullscreenJSON);
    if (fsParsedTmp === true || fsParsedTmp === false) fsParsed = fsParsedTmp;
  } catch (e) {
    /**/
  }

  return (
    <AppContext.Provider
      value={{
        isDraftMode,
        setIsDraftMode: (isDraft: boolean) => setLocalStorageDraft(JSON.stringify(isDraft)),

        legal,
        platform,
        staff,

        articles,
        articlesDraft,
        chiefArticles,

        sections,
        sectionsDraft,
        chiefSections,

        categories,
        categoriesDraft,
        chiefCategories,

        changelogSections,
        changelogArticles,

        config,
        configDraft,

        images,
        externals,

        publishState,
        setPublishState: (state: PublishState) => setPublishState(state),
        publishResult,
        setPublishResult: (report: PublishResultData | null) => setPublishResult(report),

        fullscreen: fsParsed,
        setFullscreen: (fs: boolean) => setFullscreenJSON(JSON.stringify(fs)),
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
