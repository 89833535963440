import { ReactElement, useEffect, useRef, useState, CSSProperties } from 'react';
import _ from 'lodash';
import { Link, useLocation, useParams } from 'react-router-dom';
import './CategoryNavPublish.scss';
import { Article } from '../../Types';
import { useCategoryTree, useSections } from '../ContextProviders/AppContext';
import Collapsible from 'react-collapsible';
import { useStyle } from '../ContextProviders/Style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { useConcreteProject } from '../ContextProviders/ProjectContext';

const ArticleLink = ({
  article: { fId, category, name, deleted },
  style = {},
}: {
  article: Article;
  style?: CSSProperties;
}) => {
  const params = useParams<{ articleId: string }>();
  return (
    <Link
      to={`/category/${category}/${fId}`}
      key={fId}
      className={`${deleted ? 'article-deleted' : ''} ${fId === params.articleId ? 'article-active' : ''} nav-article`}
      style={style}
    >
      <span>{name}</span>
    </Link>
  );
};

export const CategoryNavPublish = ({ id }: { id: string }): ReactElement => {
  const categoryTree = useCategoryTree(id);
  const categoryTreeRef = useRef(categoryTree);
  categoryTreeRef.current = categoryTree;
  const appStyle = useStyle();
  const location = useLocation();
  const [openItems, setOpenItems] = useState<Record<string, boolean>>({});
  const [initialized, setInitialized] = useState(false);
  const sections = useSections();
  const opened = !useConcreteProject().collapseSectionsByDefault;

  useEffect(() => {
    const refSections = categoryTreeRef.current?.sections;
    if (categoryTree?.fId && refSections !== undefined && !location.state?.sectionId && !initialized) {
      setOpenItems((oi) => refSections.reduce((acc, { fId }) => ({ [fId]: opened, ...acc }), oi));
      setInitialized(true);
    }
  }, [categoryTree?.fId, location.state?.sectionId, sections, initialized, opened]);

  useEffect(() => {
    if (location.state?.sectionId) {
      setOpenItems((oi) => ({ [location.state?.sectionId]: true }));
      setInitialized(true);
    }
  }, [location.state?.sectionId]);

  const SectionItem = ({ name, id }: { name: string; id: string }) => (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '95%',
        minHeight: '3rem',
        paddingLeft: '1rem',
        cursor: 'pointer',
      }}
    >
      <div style={{ wordBreak: 'break-word' }}>
        <h6 style={{ color: appStyle.primaryDark, fontWeight: 'bold' }}>{name}</h6>
      </div>
      <FontAwesomeIcon
        icon={openItems[id] ? faChevronDown : faChevronRight}
        size="1x"
        color={appStyle.primaryDark}
        style={{ minWidth: '1rem' }}
      />
    </div>
  );

  if (categoryTree === undefined || !initialized) return <></>;

  return (
    <div className="category-navigation">
      {categoryTree.sections.map((ss) => (
        <div
          key={ss.fId}
          className={ss.deleted ? 'section-deleted' : ''}
          style={{ borderBottom: '1px solid lightgrey' }}
        >
          <Collapsible
            trigger={<SectionItem name={ss.name} id={ss.fId} />}
            open={openItems[ss.fId]}
            onTriggerOpening={() => setOpenItems({ ...openItems, [ss.fId]: true })}
            onTriggerClosing={() => setOpenItems({ ...openItems, [ss.fId]: false })}
            transitionTime={150}
          >
            {ss.articles.map((sa: Article) => (
              <ArticleLink article={sa} key={sa.fId} />
            ))}
          </Collapsible>
        </div>
      ))}
      <div style={{ marginTop: '1rem' }}>
        {categoryTree.defaultSection.map((a) => (
          <ArticleLink article={a} key={a.fId} />
        ))}
      </div>
    </div>
  );
};
