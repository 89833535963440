import './ExternalPage.scss';
import { ReactElement, useEffect } from 'react';
import { PlatformContentFrame } from '../../PlatformContentFrame/PlatformContentFrame';
import { useExternal, useProjectConfig } from '../../ContextProviders/AppContext';
import { useParams } from 'react-router-dom';
import { useLocalization } from '../../ContextProviders/LocalizationContext';

interface ExternalParams {
  externalId: string;
}
export const ExternalPage = (): ReactElement => {
  const localization = useLocalization();
  const { externalId } = useParams<ExternalParams>();
  const external = useExternal(externalId);

  const projectConfig = useProjectConfig();
  useEffect(() => {
    document.title = `${localization.strings.external.externalContent} | ${projectConfig.doc.name}`;
  }, [projectConfig.doc.name, localization.strings.external.externalContent]);

  if (!external) return <></>;

  return <PlatformContentFrame body={external.content} canBeUrl />;
};
