import { ArticleData, SectionData } from '@eir/core';
import {
  collection,
  addDoc,
  doc,
  Firestore,
  updateDoc,
  deleteDoc,
  writeBatch,
  deleteField,
  onSnapshot,
  getDoc,
  setDoc,
} from 'firebase/firestore';
import { deleteObject, StorageReference } from 'firebase/storage';
import { CategoryTreeNorm } from '../components/Sidebar/CategorySort/CategoryNavEdit';
import { Article, IsChangelog, IsChief, ProjectConfig, PublishResult, Section } from '../Types';

export enum Collection {
  Project = 'project',
  Platform = 'platform',
  Resources = 'resources',
  Category = 'category',
  Section = 'section',
  Article = 'article',
  Invocation = 'invocation',
  UploadedImage = 'uploadedImage',
  Configuration = 'configuration',
  EXTERNAL = 'externals',
  Features = '_features',
  AccessRoles = 'accessRoles',
  Chief = 'chief',
  Changelog = 'changelog',
  Helpers = 'aggregatedHelpers',
}

export enum Invocation {
  Delete = 'delete',
  PublishProcess = 'publishProcess',
  PinProtect = 'pinProtect',
  ProtectCategory = 'protectCategory',
  ChangeStaffCredentials = 'changeStaffCredentials',
  ChangeAccountCredentials = 'changeAccountCredentials',
  Export = 'static_export',
}

export enum InvocationDummies {
  Article = 'i_article',
  Section = 'i_section',
  Category = 'i_category',
  Image = 'i_image',
  Video = 'i_video',
  Project = 'i_project',
}

export const chiefChangelogCategoryActions = (
  firestore: Firestore,
  projectId: string,
  chiefOrChangelog: Collection.Chief | Collection.Changelog,
) => {
  return {
    sort: async (tree: CategoryTreeNorm): Promise<void> => {
      const batch = writeBatch(firestore);
      tree.sections.forEach((section, i) => {
        if (section.fId !== 'DEFAULT_SECTION')
          batch.set(
            doc(
              collection(firestore, `project/${projectId}/${chiefOrChangelog}/db/${Collection.Section}`),
              section.fId,
            ),
            { orderIndex: i },
            { merge: true },
          );
        section.articles.forEach((article, i) => {
          batch.set(
            doc(
              collection(firestore, `project/${projectId}/${chiefOrChangelog}/db/${Collection.Article}`),
              article.fId,
            ),
            { orderIndex: i, section: section.fId === 'DEFAULT_SECTION' ? null : section.fId },
            { merge: true },
          );
        });
      });
      return batch.commit();
    },
  };
};
export const chiefChangelogArticleActions = (
  firestore: Firestore,
  projectId: string,
  chiefOrChangelog: Collection.Chief | Collection.Changelog,
) => {
  return {
    create: async (article: ArticleData): Promise<Article & IsChief & IsChangelog> => {
      const addedRef = await addDoc(
        collection(firestore, `project/${projectId}/${chiefOrChangelog}/db/${Collection.Article}`),
        article,
      );
      return {
        ...(await getDoc(addedRef)).data(),
        fId: addedRef.id,
        isChief: chiefOrChangelog === Collection.Chief,
        isChangelog: chiefOrChangelog === Collection.Changelog,
      } as Article & IsChief & IsChangelog;
    },
    remove: (articleId: string) =>
      deleteDoc(doc(firestore, `project/${projectId}/${chiefOrChangelog}/db/${Collection.Article}/${articleId}`)),
    update: async (articleId: string, articleUpdate: Partial<ArticleData>) => {
      const { name, content, orderIndex, category, section } = articleUpdate;
      const maximumKeysUpdate = Object.fromEntries(
        Object.entries({ name, content, orderIndex, section, category }).filter(([k, v]) => v !== undefined),
      );
      const ref = doc(firestore, `project/${projectId}/${chiefOrChangelog}/db/${Collection.Article}/${articleId}`);
      await updateDoc(ref, maximumKeysUpdate);
      return {
        ...(await getDoc(ref)).data(),
        fId: ref.id,
        isChief: chiefOrChangelog === Collection.Chief,
        isChangelog: chiefOrChangelog === Collection.Changelog,
      } as Article & IsChief & IsChangelog;
    },
  };
};
export const chiefChangelogSectionActions = (
  firestore: Firestore,
  projectId: string,
  chiefOrChangelog: Collection.Chief | Collection.Changelog,
) => {
  return {
    create: async (section: SectionData): Promise<Section & IsChief & IsChangelog> => {
      const addedRef = await addDoc(
        collection(firestore, `project/${projectId}/${chiefOrChangelog}/db/${Collection.Section}`),
        section,
      );
      return {
        ...(await getDoc(addedRef)).data(),
        fId: addedRef.id,
        isChief: chiefOrChangelog === Collection.Chief,
        isChangelog: chiefOrChangelog === Collection.Changelog,
      } as Section & IsChief & IsChangelog;
    },
    remove: (sectionId: string) =>
      deleteDoc(doc(firestore, `project/${projectId}/${chiefOrChangelog}/db/${Collection.Section}/${sectionId}`)),
    update: async (sectionId: string, sectionUpdate: Partial<ArticleData>) => {
      const { name, orderIndex, category } = sectionUpdate;
      const maximumKeysUpdate = Object.fromEntries(
        Object.entries({ name, orderIndex, category }).filter(([k, v]) => v !== undefined),
      );
      const ref = doc(firestore, `project/${projectId}/${chiefOrChangelog}/db/${Collection.Section}/${sectionId}`);
      await updateDoc(ref, maximumKeysUpdate);
      return {
        ...(await getDoc(ref)).data(),
        fId: ref.id,
        isChief: chiefOrChangelog === Collection.Chief,
        isChangelog: chiefOrChangelog === Collection.Changelog,
      } as Section & IsChief & IsChangelog;
    },
  };
};

export const changelogArticleActions = (firestore: Firestore, projectId: string) =>
  chiefChangelogArticleActions(firestore, projectId, Collection.Changelog);
export const changelogSectionActions = (firestore: Firestore, projectId: string) =>
  chiefChangelogSectionActions(firestore, projectId, Collection.Changelog);
export const changelogCategoryActions = (firestore: Firestore, projectId: string) =>
  chiefChangelogCategoryActions(firestore, projectId, Collection.Changelog);
export const chiefArticleActions = (firestore: Firestore, projectId: string) =>
  chiefChangelogArticleActions(firestore, projectId, Collection.Chief);
export const chiefSectionActions = (firestore: Firestore, projectId: string) =>
  chiefChangelogSectionActions(firestore, projectId, Collection.Chief);
export const chiefCategoryActions = (firestore: Firestore, projectId: string) =>
  chiefChangelogCategoryActions(firestore, projectId, Collection.Chief);

/* STANDARD CATEGORY */
export const articleActions = (firestore: Firestore, projectId: string, currentUser: string | null = '?') => {
  currentUser = currentUser || '?';

  return {
    create: async (article: ArticleData): Promise<Article & IsChief & IsChangelog> => {
      const addedRef = await addDoc(collection(firestore, `project/${projectId}/${Collection.Article}Draft`), {
        ...article,
        state: 'created',
        lastUpdatedBy: currentUser,
      });

      return { ...(await getDoc(addedRef)).data(), fId: addedRef.id } as Article & IsChief & IsChangelog;
    },
    remove: async (articleId: string, userId: string) => {
      const removedRef = doc(firestore, `project/${projectId}/${Collection.Article}Draft/${articleId}`);
      await updateDoc(removedRef, { deleted: true, state: 'deleted', lastUpdatedBy: currentUser });
      return { ...(await getDoc(removedRef)).data(), fId: removedRef.id } as Article & IsChief & IsChangelog;
    },
    update: async (
      articleId: string,
      articleUpdate: Partial<ArticleData>,
    ): Promise<Article & IsChief & IsChangelog> => {
      const { name, content, deleted, section, category } = articleUpdate;
      const maximumKeysUpdate = Object.fromEntries(
        Object.entries({ name, content, deleted, section, category }).filter(([k, v]) => v !== undefined),
      );
      const ref = doc(firestore, `project/${projectId}/${Collection.Article}Draft/${articleId}`);
      await updateDoc(ref, {
        ...maximumKeysUpdate,
        deleted: deleted || deleteField(),
        state: (await getDoc(ref)).data()?.state === 'created' ? 'created' : 'updated',
        lastUpdatedBy: currentUser,
      });

      return { ...(await getDoc(ref)).data(), fId: ref.id } as Article & IsChief & IsChangelog;
    },
  };
};

export const sectionActions = (firestore: Firestore, projectId: string) => {
  return {
    create: async (section: SectionData) => {
      const addedRef = await addDoc(collection(firestore, `project/${projectId}/${Collection.Section}Draft`), section);
      return { ...(await getDoc(addedRef)).data(), fId: addedRef.id } as Section;
    },
    remove: async (sectionId: string, userId: string) => {
      const removeRef = doc(firestore, `project/${projectId}/${Collection.Section}Draft/${sectionId}`);
      await updateDoc(removeRef, { deleted: true });
      return { ...(await getDoc(removeRef)).data(), fId: removeRef.id } as Section;
    },
    update: async (sectionId: string, sectionUpdate: Partial<SectionData>): Promise<Section> => {
      const { name, deleted, category } = sectionUpdate;
      const ref = doc(firestore, `project/${projectId}/${Collection.Section}Draft/${sectionId}`);
      await updateDoc(ref, { name, deleted: deleted === true ? true : deleteField(), category });
      return { ...(await getDoc(ref)).data(), fId: ref.id } as Section;
    },
  };
};

export const appActions = (firestore: Firestore, projectId: string) => {
  return {
    publish: async (
      userId: string,
      notifyMessage: string | undefined,
      shouldSendNotification: boolean,
      shouldNotifyAll: boolean | undefined,
      targetUsers: string[] | undefined,
      articles: string[],
    ): Promise<PublishResult> => {
      try {
        const report = await addDoc(
          collection(
            firestore,
            `${Collection.Invocation}/${Invocation.PublishProcess}/${InvocationDummies.Project}/${projectId}/${userId}`,
          ),
          { shouldSendNotification, shouldNotifyAll, targetUsers, notifyMessage, articles },
        );

        return new Promise<PublishResult>((resolve, reject) => {
          const unsub = onSnapshot(
            report,
            (snap) => {
              const result = snap.data() as PublishResult;
              if (result.completedOn) {
                resolve(result);
                unsub();
              }
            },
            (error) => {
              reject(error);
              unsub();
            },
          );
          setTimeout(() => {
            reject(new Error('Timeout in publish process'));
            unsub();
          }, 30000);
        });
      } catch (error) {
        console.log(error);
        return new Promise((resolve, reject) => reject(error));
      }
    },
  };
};

export const categoryActions = (firestore: Firestore, projectId: string) => {
  return {
    sort: async (tree: CategoryTreeNorm): Promise<void> => {
      const batch = writeBatch(firestore);
      tree.sections.forEach((section, i) => {
        if (section.fId !== 'DEFAULT_SECTION')
          batch.set(
            doc(collection(firestore, `project/${projectId}/${Collection.Section}Draft`), section.fId),
            { orderIndex: i },
            { merge: true },
          );
        section.articles.forEach((article, i) => {
          batch.set(
            doc(collection(firestore, `project/${projectId}/${Collection.Article}Draft`), article.fId),
            { orderIndex: i, section: section.fId === 'DEFAULT_SECTION' ? null : section.fId },
            { merge: true },
          );
        });
      });
      return batch.commit();
    },
  };
};

export const configActions = (firestore: Firestore, projectId: string) => {
  return {
    update: (configUpdate: Partial<ProjectConfig>) =>
      updateDoc(doc(firestore, `${Collection.Project}/${projectId}/${Collection.Configuration}/draft`), configUpdate),
  };
};

export const accountActions = (firestore: Firestore, projectId: string) => {
  return {
    updateAdminPassword: (newPassword: string, userId: string) =>
      addDoc(
        collection(
          firestore,
          `${Collection.Invocation}/${Invocation.ChangeAccountCredentials}/${InvocationDummies.Project}/${projectId}/${userId}`,
        ),
        { password: newPassword },
      ),
    setPermission: (categoryId: string, userId: string, canRead: boolean) =>
      setDoc(doc(firestore, `cmsUser/${userId}`), { categoryPermissions: { [categoryId]: canRead } }, { merge: true }),
  };
};

export const invocationActions = (firestore: Firestore, projectId: string) => ({
  export: () =>
    new Promise<void>((resolve, reject) => {
      let count = 0;
      const unsub = onSnapshot(
        collection(firestore, `project/${projectId}/exports`),
        () => {
          if (count === 1) {
            unsub();
            resolve();
          }
          count++;
        },
        (err) => {
          unsub();
          reject(err);
        },
      );
      void setDoc(doc(firestore, `${Collection.Invocation}/${Invocation.Export}`), {});
    }),
  deleteExports: (zipRef: StorageReference) =>
    new Promise<void>((resolve, reject) => {
      const unsub = onSnapshot(
        collection(firestore, `project/${projectId}/exports`),
        (snap) => {
          if (snap.docs.length > 0) {
            void Promise.all(snap.docs.map((d) => deleteDoc(d.ref), deleteObject(zipRef)))
              .then(() => {
                unsub();
                resolve();
              })
              .catch((err) => {
                unsub();
                reject(err);
              });
          }
        },
        (err) => {
          unsub();
          reject(err);
        },
      );
    }),
});
