import { ReactElement, useEffect, useState } from 'react';
import { Redirect, useLocation, useParams } from 'react-router-dom';
import {
  useArticle,
  useCategory,
  useCategoryTree,
  useFullscreen,
  useProjectConfig,
} from '../../ContextProviders/AppContext';
import { CategoryNav } from '../../Sidebar/CategoryNav';
import './MainBody.scss';

import { PageWithSidebar } from '../PageTypes';
import { ArticleBody } from '../../ArticleBody/ArticleBody';
import { useAuth } from '../../ContextProviders/Auth';
import { FabButtonCategory } from '../../FabButton/FabButton';
import { FourOhFour } from '../404/FourOhFour';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-solid-svg-icons';
import { useLocalization } from '../../ContextProviders/LocalizationContext';

interface CategoryArticleBodyProps {
  categoryId: string;
  articleId?: string;
}
export const CategoryArticlePage = ({ categoryId, articleId }: CategoryArticleBodyProps) => {
  const article = useArticle(articleId);
  const projectConfig = useProjectConfig();
  const tree = useCategoryTree(categoryId);
  const category = useCategory(categoryId);
  const [fullscreen] = useFullscreen();
  const [isVisible, setIsVisible] = useState(true);
  const location = useLocation();
  const [fs] = useFullscreen();

  const f404ifNotFound =
    (tree?.sections.reduce((sum, s) => sum + s.articles.length, 0) || 0) + (tree?.defaultSection.length || 0) !== 0;

  useEffect(() => {
    setIsVisible(true);
  }, [location.pathname]);

  useEffect(() => {
    if (category?.name && projectConfig.doc?.name && !article?.name)
      document.title = `${category.name} | ${projectConfig.doc.name}`;
    else if (article?.name && projectConfig.doc?.name) document.title = `${projectConfig.doc.name} | ${article.name}`;
  }, [category?.name, article?.name, projectConfig.doc?.name]);

  if (!tree || !category) return <></>;

  return (
    <>
      <PageWithSidebar>
        {
          (!fullscreen || !article) && (
            <>
              <aside className={isVisible ? 'sidebar' : 'sidebar closed'}>
                <CategoryNav id={categoryId} />
              </aside>
              <button className="collapse-button" onClick={() => setIsVisible(!isVisible)}>
                <FontAwesomeIcon icon={isVisible ? faAngleLeft : faAngleRight} />
              </button>
            </>
          ) /* Only show sidebar on the main category page if fullscreen */
        }
        {articleId && (
          <main className={`article padded-container ${fs ? 'fullscreen' : ''}`}>
            {article && <ArticleBody id={articleId} />}
            {!article && f404ifNotFound && <FourOhFour />}
          </main>
        )}
      </PageWithSidebar>
      {!articleId && categoryId && <FabButtonCategory id={categoryId} />}
    </>
  );
};

interface BodyParams {
  categoryId: string;
  articleId: string;
}
export const Body = (): ReactElement => {
  const localization = useLocalization();
  const { categoryId, articleId } = useParams<BodyParams>();
  const category = useCategory(categoryId);
  const auth = useAuth();

  if (!auth.user && category?.isProtected) return <Redirect to="/" />;
  if (!categoryId) throw new Error(localization.strings.category.notFoundWarning);

  return <CategoryArticlePage categoryId={categoryId} articleId={articleId} />;
};
