import { ReactElement, useEffect, useState } from 'react';
import { PageWithSidebar } from '../PageTypes';
import { useConcreteProject } from '../../ContextProviders/ProjectContext';
import { SettingsSidebar } from './SettingsSidebar';
import { useFirestore, useStorage } from '../../ContextProviders/Firebase';
import { IconButton } from '../../Buttons/Buttons';
import { faSave, faDownload, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { ref, getDownloadURL, getMetadata, FullMetadata, updateMetadata, deleteObject } from '@firebase/storage';
import { invocationActions } from '../../../Hooks/DatabaseActions';
import { useProjectConfig } from '../../ContextProviders/AppContext';
import { toasts } from '../../../shared';
import { Row } from 'reactstrap';
import { useLocalization } from '../../ContextProviders/LocalizationContext';

export const Export = (): ReactElement => {
  const localization = useLocalization();
  const project = useConcreteProject();
  const firestore = useFirestore();
  const projectConfig = useProjectConfig();
  const storage = useStorage();
  const fileRef = ref(storage, 'export.zip');
  const invoke = invocationActions(firestore, project.id);
  const [isExporting, setIsExporting] = useState(false);
  const [fileExists, setFileExists] = useState(false);
  const [zipurl, setZipurl] = useState('');
  const [meta, setMeta] = useState<FullMetadata>();
  const [dateString, setDateString] = useState<string>('');

  useEffect(() => {
    document.title = `${localization.strings.settings.export.title} | ${projectConfig.doc.name}`;
  }, [projectConfig.doc.name, localization.strings.settings.export.title]);

  const _export = async () => {
    toasts.info(localization.strings.settings.export.exportStarted);
    setIsExporting(true);
    try {
      await invoke.export();
      await checkRef();
      toasts.success(localization.strings.settings.export.exportFinished);
    } catch (error) {
      toasts.error(error);
    }
    setIsExporting(false);
  };

  const _downloadExport = () => {
    // set a custom file download name before downloading
    if (meta && !meta.contentDisposition) {
      void updateMetadata(fileRef, {
        contentDisposition: `attachment; filename=Export ${dateString}.zip`,
      })
        .then(() => {
          window.open(zipurl);
        })
        .catch((err) => {
          toasts.error(localization.strings.settings.export.failedToDownload);
          console.log(err);
        });
    } else {
      window.open(zipurl);
    }
  };

  const _deleteExport = async () => {
    try {
      await deleteObject(ref(storage, 'export.zip'));
      toasts.error(localization.strings.settings.export.deleteFinished);
      await checkRef();
    } catch (error) {
      toasts.error(localization.strings.error.unknown);
      console.log(error);
    }
  };

  // get download url, disable download if no export has been stored
  const checkRef = async () => {
    try {
      const url = await getDownloadURL(fileRef);
      const metadata = await getMetadata(fileRef);

      setZipurl(url);
      setMeta(metadata);
      setDateString(
        `${new Date(metadata.timeCreated).toLocaleDateString('sv')} ${new Date(metadata.timeCreated).toLocaleTimeString(
          'sv',
        )}`,
      );
      setFileExists(true);
    } catch (error) {
      setFileExists(false);
    }
  };

  useEffect(() => {
    void checkRef();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWithSidebar>
      <SettingsSidebar />
      <main className="padded-container">
        <div className="settings-page">
          <h1>{localization.strings.settings.export.title}</h1>
          <p style={{ color: 'var(--grayscale-2)' }}>{localization.strings.settings.export.hint}</p>
          <hr />
          <IconButton
            theme="dark"
            isLoading={isExporting}
            onClick={_export}
            icon={faSave}
            text={
              isExporting
                ? localization.strings.settings.export.generating
                : localization.strings.settings.export.create
            }
          />
          <div className="email-list acc-mng-module shadow-sm">
            <Row className="align-items-center text-muted">
              <span className="col-md-8 col">
                {!fileExists ? <em>{localization.strings.settings.export.notFound}</em> : `Export ${dateString}`}
              </span>
              <div className="col-md-4 col text-end">
                <IconButton
                  disabled={!fileExists}
                  theme="dark"
                  onClick={_downloadExport}
                  icon={faDownload}
                  tooltip={localization.strings.settings.export.download}
                />
                <IconButton
                  disabled={!fileExists}
                  theme="dark"
                  onClick={_deleteExport}
                  icon={faTrash}
                  tooltip={localization.strings.settings.export.delete}
                />
              </div>
            </Row>
          </div>
        </div>
      </main>
    </PageWithSidebar>
  );
};
