import { faCircleInfo, faEye, faEyeSlash, faSave, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEqual } from 'lodash';
import { FC, useState, FormEvent } from 'react';
import { toast } from 'react-toastify';
import { Form, FormFeedback, Input, InputGroup, PopoverBody, PopoverHeader, UncontrolledPopover } from 'reactstrap';
import { accountActions } from '../../../Hooks/DatabaseActions';
import { useAuth } from '../../ContextProviders/Auth';
import { useFirestore, useMonofunction } from '../../ContextProviders/Firebase';
import { useConcreteProject } from '../../ContextProviders/ProjectContext';
import { IconButton } from '../../Buttons/Buttons';
import { useLocalization } from '../../ContextProviders/LocalizationContext';

interface Props {
  label: string;
  passType: 'STAFF' | 'ADMIN';
  initialValue?: string;
  userId: string;
}

const ChangePasswordForm: FC<Props> = (props) => {
  const localization = useLocalization();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [passMain, setPassMain] = useState<string>(props.initialValue ?? '');
  const [passRetype, setPassRetype] = useState<string>('');
  const [errors, setErrors] = useState<{ main: boolean; notUnique: boolean; retype: boolean }>({
    main: false,
    notUnique: false,
    retype: false,
  });
  const [loading, setLoading] = useState<boolean>(false);

  const firebase = useFirestore();
  const project = useConcreteProject();
  const auth = useAuth();
  const { updateAdminPassword } = accountActions(firebase, project.id);
  const firebaseFunctions = useMonofunction();

  const setAllErrors = (value: boolean) => {
    setErrors({ main: value, notUnique: value, retype: value });
  };

  const checkValidity = (isAdmin: boolean): boolean => {
    const matchedCase: string[] = [];

    if (isAdmin) {
      if (passMain.length < 8 || passMain.length > 20) {
        setErrors({ ...errors, main: true });
        return false;
      }

      matchedCase.push('[!"#¤%&/()=?¡@£$€¥{øæ~}`´*¨_.,µ¶§½]'); // Special Character
      matchedCase.push('[A-ZÄÅÖ]'); // Uppercase Alphabets
      matchedCase.push('[0-9]'); // Numbers - optional
      matchedCase.push('[a-zäöå]'); // Lowercase Alphabets

      let ctr = 0;
      if (passMain.length > 7) {
        ctr++;
      }
      for (let i = 0; i < matchedCase.length; i++) {
        if (new RegExp(matchedCase[i]).test(passMain)) {
          ctr++;
        }
      }
      if (passMain.trim() !== passMain) {
        // there exits whitespace in the string
        ctr = 0;
      }

      if (ctr < 4 || passMain.length < 8) {
        setErrors({ ...errors, main: true });
        return false;
      }
    } else if (passMain.length < 6 || passMain.length > 20) {
      // Only check length for staff accounts
      setErrors({ ...errors, main: true });
      return false;
    }

    setErrors({ ...errors, main: false });
    return checkPassMatch();
  };

  const checkPassMatch = (): boolean => {
    if (!isEqual(passMain, passRetype)) {
      setErrors({ ...errors, retype: true });
      return false;
    }
    setErrors({ ...errors, retype: false });
    return true;
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    if (!auth.user?.uid) throw new Error(localization.strings.auth.notAuthenticated);
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    if (props.passType === 'ADMIN') {
      if (checkValidity(true)) {
        try {
          const result = await updateAdminPassword(passMain, auth.user.uid);
          if (result) {
            toast.success(localization.strings.auth.passwordChangeSuccess);
            setAllErrors(false);
            setPassMain('');
            setPassRetype('');
          }
        } catch (error) {
          setAllErrors(false);
          toast.error(localization.strings.auth.passwordChangeFailure);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    }
    if (props.passType === 'STAFF') {
      if (checkValidity(false)) {
        void firebaseFunctions
          .ChangeStaffPassword({ uid: props.userId, password: passMain })
          .then(() => {
            toast.success(localization.strings.auth.passwordChangeSuccess);
            setAllErrors(false);
            setPassMain('');
            setPassRetype('');
          })
          .catch(() => {
            toast.error(localization.strings.auth.passwordChangeFailure);
            setErrors({ main: true, notUnique: true, retype: false });
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <div className="mb-2">
        <h5>{props.label}</h5>
        <InputGroup style={{ width: 'calc(100% - 25px)' }}>
          <Input
            type={showPassword ? 'text' : 'password'}
            placeholder={localization.strings.auth.passwordPlaceholder}
            invalid={errors.main}
            value={passMain}
            onChange={(e) => {
              setPassMain(e.target.value);
              setErrors({ ...errors, retype: false });
            }}
          />
          <div
            style={{
              position: 'absolute',
              right: '-2.1rem',
              top: '0.2rem',
              cursor: 'pointer',
              padding: '0.2rem 0.4rem',
            }}
            onClick={() => setShowPassword(!showPassword)}
          >
            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye}></FontAwesomeIcon>
          </div>
          <FormFeedback>
            {errors.notUnique
              ? localization.strings.auth.staffPasswordNotUnique
              : localization.strings.auth.invalidPasswordFormat}
          </FormFeedback>
        </InputGroup>
      </div>
      <div className="mb-2">
        <InputGroup style={{ width: 'calc(100% - 25px)' }}>
          <Input
            type={showPassword ? 'text' : 'password'}
            placeholder={localization.strings.auth.passwordPlaceholderRetype}
            aria-label="retype password"
            aria-describedby="basic-addon1"
            invalid={errors.retype}
            value={passRetype}
            onChange={(e) => {
              setPassRetype(e.target.value);
              setErrors({ ...errors, retype: false });
            }}
          />
          <FormFeedback>{localization.strings.auth.invalidPasswordRetype}</FormFeedback>
        </InputGroup>
      </div>
      <IconButton
        type="submit"
        theme="dark"
        isLoading={loading}
        icon={faSave}
        text={localization.strings.global.save}
      />
      <IconButton
        type="button"
        theme="dark"
        onClick={() => {
          setPassMain('');
          setPassRetype('');
          setErrors({
            main: false,
            notUnique: false,
            retype: false,
          });
        }}
        disabled={loading}
        icon={faXmark}
        text={localization.strings.global.clear}
      />
      <IconButton type="button" id={`${props.passType}-info-button`} icon={faCircleInfo} theme="dark" />
      <UncontrolledPopover placement="right-start" target={`${props.passType}-info-button`} trigger="click">
        {props.passType === 'ADMIN' ? (
          <>
            <PopoverHeader> {localization.strings.auth.passwordRules.adminHeader}</PopoverHeader>
            <PopoverBody>
              <div>
                <p className="p-0 m-0"> {localization.strings.auth.passwordRules.lowercase}</p>
                <p className="p-0 m-0"> {localization.strings.auth.passwordRules.uppercase}</p>
                <p className="p-0 m-0"> {localization.strings.auth.passwordRules.special}</p>
                <p className="p-0 m-0"> {localization.strings.auth.passwordRules.number}</p>
                <p className="p-0 m-0"> {localization.strings.auth.passwordRules.adminMin}</p>
                <p className="p-0 m-0"> {localization.strings.auth.passwordRules.whitespace}</p>
              </div>
            </PopoverBody>
          </>
        ) : (
          <>
            <PopoverHeader> {localization.strings.auth.passwordRules.staffHeader}</PopoverHeader>
            <PopoverBody>
              <div>
                <p className="p-0 m-0"> {localization.strings.auth.passwordRules.staffMin} </p>
                <p className="p-0 m-0"> {localization.strings.auth.passwordRules.staffMax}</p>
              </div>
            </PopoverBody>
          </>
        )}
      </UncontrolledPopover>
    </Form>
  );
};

export default ChangePasswordForm;
