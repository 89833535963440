import { LanguageCode } from '@eir/core';

export interface LocalizedStrings {
  auth: {
    signInButton: string;
    signOutButton: string;
    emailTitle: string;
    emailPlaceholder: string;
    logout: string;
    error: {
      unknown: string;
      invalidCredentials: string;
      invalidStaffPassword: string;
    };
    wrongProject: string;
    staffAccount: string;
    adminAccount: string;
    authenticated: string;
    loggedout: string;
    notAuthenticated: string;
    notAuthorized: string;
    resetEmailSent: string;
    resetEmailFail: string;
    passwordTitle: string;
    passwordPlaceholder: string;
    passwordPlaceholderRetype: string;
    passwordChangeSuccess: string;
    passwordChangeFailure: string;
    staffPasswordNotUnique: string;
    passwordRules: {
      adminHeader: string;
      staffHeader: string;
      lowercase: string;
      uppercase: string;
      special: string;
      number: string;
      whitespace: string;
      adminMin: string;
      staffMin: string;
      staffMax: string;
    };
    invalidPasswordFormat: string;
    invalidPasswordRetype: string;
    welcomeBack: string;
    loginToGetAccess: string;
    forgotPasswordClick: string;
    here: string;
    recoveryEmailInfo: string;
    resetPassword: string;
    loginToSee: string;
  };
  error: {
    error: string;
    unknown: string;
    404: string;
  };
  external: {
    externalContent: string;
  };
  adminViewMode: {
    enterPublishMode: string;
    onlyPublishedMaterialIsShown: string;
    publishTip: string;
    editTip: string;
  };
  global: {
    save: string;
    doNotSave: string;
    cancel: string;
    preview: string;
    view: string;
    edit: string;
    delete: string;
    loading: string;
    startApplication: string;
    searchResult: string;
    search: string;
    allResults: string;
    feedbackSubject: string;
    privacyPolicy: string;
    termsOfUse: string;
    fileFromatError: string;
    print: string;
    create: string;
    clear: string;
    ok: string;
    send: string;
    online: string;
    offline: string;
    clickToReload: string;
    clickToReloadFull: string;
    linkTo: string;
    linkCopied: string;
    insertAnchorlink: string;
    homePage: string;
    feedback: {
      feedback: string;
      feedbackAndPrint: string;
      send: string;
      noFeedbackEmailExist: string;
    };
    fullscreen: string;
    addurl: string;
    iframTooltip: string;
    youAreIn: string;
    selectAll: string;
  };
  publish: {
    publish: string;
    publishing: string;
    start: string;
    publishOk: string;
    publishFail: string;
    publishMode: string;
    editMode: string;
    defaultPublishMessage: string;
    setPublishMessage: string;
    publishWithNotification: string;
    help: {
      title: string;
      whatIsIncludedWhenPublishing: {
        title: string;
        articles: string;
        alwaysIncludedList: string[];
        lastUpdatedBy: string;
      };
      sidebarSettings: {
        title: string;
        publishMessage: string;
        sendNotification: string;
      };
    };
    titles: {
      lastUpdated: string;
      lastUpdatedBy: string;
      lastUpdatedDate: string;
      name: string;
      state: string;
    };
    editStates: {
      created: string;
      deleted: string;
      updated: string;
    };
    noArticlesHaveBeenEdited: string;
    noStaffAccountsExist: string;
    atClock: string;
    publishMessagePlaceholder: string;
    errorLoadingAccounts: string;
    linksWithIssues: string;
    imagesWithIssues: string;
    notificationSettings: string;
    notifyAll: string;
    chooseTargets: string;
  };
  settings: {
    settings: string;
    updateOk: string;
    updateFailed: string;
    email: string;
    emailSettings: string;
    addEmailsHint: string;
    categoryAccessHint: string;
    emailAlreadyExist: string;
    staffAccountMng: string;
    staffAccountCategories: string;
    staffAccountCategoriesPlaceholder: string;
    accountMng: string;
    selectAccount: string;
    emptyAccountSearch: string;
    changeAdminPass: string;
    changeStaffPass: string;
    passChangeSuccess: string;
    passChangeFail: string;
    emailError: string;
    projectSettings: string;
    projectNameHint: string;
    projectOrganisationHint: string;
    projectPublisherHint: string;
    handbook: string;
    categories: string;
    accessRoles: string;
    access: string;
    selectLanguage: string;
    export: {
      create: string;
      delete: string;
      deleteFinished: string;
      download: string;
      exportStarted: string;
      exportFinished: string;
      generating: string;
      hint: string;
      title: string;
      notFound: string;
      failedToDownload: string;
    };
  };
  sorting: {
    articleSort: string;
    sectionSort: string;
    articleEdit: string;
    sectionEdit: string;
    titleForm: string;
    cancelSorting: string;
    saveSorting: string;
    sortingArticlesHasChanged: string;
    sortingSectionsHasChanged: string;
    unsavedChanges: string;
    sortingHasChanged: string;
  };
  article: {
    article: string;
    draftModalHeader: string;
    draftModalBody: string;
    articleSavedOk: string;
    deleteModalHeader: string;
    deleteModalBody: string;
    deleteModalBodyPerma: string;
    deleteOk: string;
    addOk: string;
    sortingSaved: string;
    addNewArticle: string;
    wrongArticleName: string;
    notFoundWarning: string;
    updateOk: string;
    updateFailed: string;
    fileTooLarge: string;
    protectedArticle: {
      errorMessage: string;
      errorMessageStaff: string;
    };
  };
  category: {
    category: string;
    lock: string;
    lockCategoryInputHint: string;
    lockOk: string;
    protectOk: string;
    lockFail: string;
    protectFail: string;
    login: string;
    password: string;
    pinCheckNotValid: string;
    pinAddNotValid: string;
    notFoundWarning: string;
    updated: string;
    updateError: string;
    protectedCategory: {
      chiefErrorMessage: string;
      defaultErrorMessage: string;
    };
  };
  section: {
    section: string;
    defaultSection: string;
    deleteModalHeader: string;
    deleteModalBody: string;
    deleteModalBodyPerma: string;
    sectionDeleted: string;
    sortingSaved: string;
    addOk: string;
    addError: string;
    deleteOk: string;
    addNewSection: string;
    wrongSectionName: string;
  };
  project: {
    notLoaded: string;
    noStaffAccount: string;
  };
  forms: {
    placeHolders: {
      createNewSection: string;
      sectionName: string;
      articleName: string;
    };
    messages: {
      valueToShort: string;
    };
  };
  search: {
    relevance: string;
    alphabetical: string;
    up: string;
    down: string;
    allResults: string;
    seeAll: string;
    searchSuggest: string;
    searchError: string;
    pageNumberError: string;
    resultError: string;
  };
  versionOutdated: {
    title: string;
    body: string;
    button: string;
  };
}

export const en: LocalizedStrings = {
  auth: {
    signInButton: 'Sign in',
    signOutButton: 'Sign out',
    emailTitle: 'Email',
    emailPlaceholder: 'your@email.com',
    passwordTitle: 'Password',
    passwordPlaceholder: 'Password',
    passwordPlaceholderRetype: 'Retype password',
    logout: 'Logout',
    error: {
      unknown: 'Unknown error',
      invalidCredentials: 'Invalid email/password',
      invalidStaffPassword: 'Invalid staff account password',
    },
    wrongProject: 'You cannot login into this project!',
    staffAccount: 'Staff',
    adminAccount: 'Administrator',
    authenticated: 'You are logged in as',
    loggedout: 'You have been logged out',
    notAuthenticated: 'You are not logged in',
    notAuthorized: 'You are not authorized',
    passwordChangeFailure: 'Failed to update password',
    passwordChangeSuccess: 'Password updated',
    invalidPasswordFormat: 'Password format is wrong!',
    invalidPasswordRetype: 'Passwords do not match!',
    staffPasswordNotUnique: 'Password for staff account must be unique',
    welcomeBack: 'Welcome back',
    loginToGetAccess: 'Log in to access the platform',
    forgotPasswordClick: 'Forgot password? Click',
    here: 'here',
    resetEmailSent: 'Email sent. Check your inbox',
    resetEmailFail: 'Unknown problem. Email not sent, contact support.',
    recoveryEmailInfo:
      'Input the e-mail you use to sign in to the platform to get a password recovery e-mail. If the e-mail does not arrive or if you have other questions, contact Softwerk support',
    resetPassword: 'Reset password',
    loginToSee: 'Log in to access the content',
    passwordRules: {
      adminHeader: 'Password Admin account',
      staffHeader: 'Password Staff account',
      lowercase: 'At least one lowercase letter',
      uppercase: 'At least one uppercase letter',
      special: 'At least one special character [?,!,$,...]',
      number: 'At least one number',
      whitespace: 'No whitespace',
      adminMin: 'Minimum 8 characters long',
      staffMin: 'Minimum 6 characters',
      staffMax: 'Maximum 20 characters',
    },
  },
  error: {
    error: 'Error',
    unknown: 'Something went wrong!',
    404: 'Content not found',
  },
  external: {
    externalContent: 'External Content',
  },
  adminViewMode: {
    enterPublishMode: 'View published content',
    onlyPublishedMaterialIsShown: 'only published content is shown',
    editTip:
      'Changes made in this mode can only be viewed by Admins. Only after you have published will the new changes be visible to non-Admins.',
    publishTip:
      'In this mode you can only view material that has already been published. The version categories, sections, and articles you see is the same as what a non-Admin would see. You can not make any changes in this mode.',
  },
  global: {
    save: 'Save',
    doNotSave: 'Do not save',
    cancel: 'Cancel',
    edit: 'Edit',
    preview: 'Preview',
    view: 'View',
    delete: 'Delete',
    loading: 'Loading...',
    startApplication: 'Start Application...',
    searchResult: 'Search Result',
    search: 'Search',
    allResults: 'All results for',
    feedbackSubject: 'A new feedback submitted on an article',
    privacyPolicy: 'Privacy Policy',
    termsOfUse: 'Legal documents',
    fileFromatError: 'File extention is not supported',
    print: 'Print',
    create: 'Create',
    clear: 'Clear',
    ok: 'OK',
    send: 'Send',
    online: 'You are online',
    offline: 'You are offline. Some functionality is limited.',
    clickToReload: 'Reload component',
    clickToReloadFull: 'Reload the page',
    linkTo: 'Link to',
    linkCopied: 'The link has been copied to your clipboard',
    insertAnchorlink: 'Insert anchor link',
    homePage: 'Home page',
    fullscreen: 'Fullscreen',
    feedback: {
      feedback: 'Feedback',
      feedbackAndPrint: 'Feedback & Print',
      send: 'Send feedback',
      noFeedbackEmailExist: 'No feedback email has been registered',
    },
    addurl: 'Embed external content',
    iframTooltip:
      'You can enter your value in pixels or percentages.Please write % or px after the value. Default value:',
    youAreIn: 'You are in',
    selectAll: 'Select all',
  },
  publish: {
    publish: 'Publish',
    publishing: 'Publishing',
    start: 'Publishing process started',
    publishOk: 'Publishing done',
    publishFail: 'Publishing failed',
    publishMode: 'Published mode',
    editMode: 'Edit mode',
    defaultPublishMessage: 'New content has been published.',
    setPublishMessage: 'Set publish message',
    publishWithNotification: 'Publish with notification',
    linksWithIssues: 'Links with issues',
    imagesWithIssues: 'Images with issues',

    help: {
      title: 'Help',
      whatIsIncludedWhenPublishing: {
        title: 'What is included in a publish?',
        articles:
          'The list on the page shows all articles which have changes compared to the currently published material. Only articles whos checkbox is checked will be included when publishing. Note that regardless of which articles are included, if any, changes to the following things will ALWAYS be included when publishing:',
        alwaysIncludedList: [
          'Locked/unlocked status or regular categories',
          'Project configuration',
          'Sections',
          'Article ordering in sektions',
        ],
        lastUpdatedBy: 'The name of the Admin who last edited an article is never visible to non-Admins.',
      },
      sidebarSettings: {
        title: 'Publish settings',
        publishMessage:
          'The publish message is included in the changelog entry, and is the message shown in the push notification.',
        sendNotification:
          '"Publish with notification" means that a push notification will be sent to all mobile devices that have the app installed.',
      },
    },
    titles: {
      lastUpdatedDate: 'Date',
      lastUpdatedBy: 'Last updated by',
      lastUpdated: 'Last updated',
      name: 'Article',
      state: 'Status',
    },
    editStates: {
      created: 'Added',
      deleted: 'Deleted',
      updated: 'Updated',
    },
    noArticlesHaveBeenEdited: 'No articles have been edited, added, or deleted since last publish!',
    noStaffAccountsExist: 'There are no staff accounts to notify!',
    atClock: 'at',
    publishMessagePlaceholder: 'Publish messsage',
    errorLoadingAccounts: 'Could not load accounts!',
    notificationSettings: 'Notification settings',
    notifyAll: 'Notify all users',
    chooseTargets: 'Choose accounts to notify',
  },
  settings: {
    settings: 'Settings',
    email: 'Feedback Email Addresses',
    emailSettings: 'Email Settings',
    addEmailsHint: 'Add emails to feedback emails.',
    categoryAccessHint:
      'Access to locked categories must be given on a per-account basis. Unlocked categories can be accessd by anyone.',
    updateOk: 'Changes are saved',
    updateFailed: 'Saving changes failed',
    emailAlreadyExist: 'Email Already exist',
    staffAccountMng: 'Staff accounts',
    staffAccountCategories: 'Change access to locked categories',
    staffAccountCategoriesPlaceholder: 'All categories are unlocked!',
    accountMng: 'Account Management',
    selectAccount: 'Select an account to edit',
    emptyAccountSearch: 'No results!',
    changeAdminPass: 'Change admin account password',
    changeStaffPass: 'Change staff account password',
    passChangeSuccess: 'Password change succeeded!',
    passChangeFail: 'Changing password failed',
    emailError: 'An error in email!',
    export: {
      create: 'Create new export',
      delete: 'Delete export',
      deleteFinished: 'Export deleted',
      download: 'Download export',
      exportFinished: 'Export generated!',
      exportStarted: 'Initiating export...',
      generating: 'Generating export...',
      hint:
        'Export an offline-only version of web app containing the latest published content. The latest export is stored and can be downloaded multiple times.',
      title: 'Export',
      notFound: 'No export has been made yet',
      failedToDownload: 'Failed to download the zip file!',
    },
    projectSettings: 'Project Settings',
    projectNameHint: 'Name',
    projectOrganisationHint: 'Organisation',
    projectPublisherHint: 'Publisher',
    handbook: 'Handbook',
    categories: 'Categories',
    accessRoles: 'Access Roles',
    access: 'Manage access to categories',
    selectLanguage: 'Select language',
  },
  sorting: {
    articleSort: 'Articles',
    sectionSort: 'Sections',
    titleForm: 'Article & Section sorting',
    articleEdit: 'Edit Articles',
    sectionEdit: 'Edit Sections',
    cancelSorting: 'Cancel Sorting',
    saveSorting: 'Save Sorting',
    sortingArticlesHasChanged: ' Sorting has changed',
    sortingSectionsHasChanged: 'Sorting has changed',
    unsavedChanges: 'You have unsaved changes',
    sortingHasChanged: 'Sorting  has changed',
  },
  article: {
    article: 'Article',
    draftModalHeader: 'Not saved content',
    draftModalBody: 'Edited content is not saved.',
    articleSavedOk: 'Article is saved',
    deleteModalHeader: 'Delete article',
    deleteModalBody: 'Are you sure you want to delete this article?',
    deleteModalBodyPerma: 'Are you sure you want to delete this article permanently?',
    deleteOk: 'Article deleted!',
    addOk: 'Article added!',
    sortingSaved: 'Article sorting Saved',
    addNewArticle: 'Add new article',
    wrongArticleName: 'Wrong article name',
    notFoundWarning: 'Article not found!',
    updateOk: 'The article has been updated',
    updateFailed: 'The article update failed',
    fileTooLarge: 'The file is too large. Max 10MB',
    protectedArticle: {
      errorMessage: 'You must be signed in to view this article.',
      errorMessageStaff: 'You must be granted access to view this article.',
    },
  },
  category: {
    category: 'Category',
    lock: 'Pin lock',
    lockCategoryInputHint:
      'Set pin code to protect the category, pin should be numbers between 4 and 6 long.' +
      'to remove the pin provide empty value',
    lockOk: 'Category pin has been set.',
    lockFail: 'Error while setting category pin.',
    login: 'View Content',
    password: 'Enter pin code',
    pinCheckNotValid: 'Pin code is not valid!',
    pinAddNotValid:
      'Provided pin is not valid should be between 4 -6 and only numbers. To remove the pin provide empty value',
    protectOk: 'Category protection changed',
    protectFail: 'Changing Category Protection Failed',
    notFoundWarning: 'Category not found!',
    updated: 'Category updated',
    updateError: 'Error when updating category',
    protectedCategory: {
      chiefErrorMessage: 'You must be an admin to view these articles.',
      defaultErrorMessage: 'You must be granted access to view these articles.',
    },
  },
  section: {
    section: 'Section',
    defaultSection: 'No section',
    deleteModalHeader: 'Delete section',
    deleteModalBody: 'Are you sure do you want to delete this section?',
    deleteModalBodyPerma: 'Are you sure do you want to delete this section permanently?',
    sectionDeleted: 'Section is deleted!',
    sortingSaved: 'Section Sorting saved',
    addOk: 'Section added',
    addError: 'Adding section failed',
    deleteOk: 'Section deleted',
    wrongSectionName: 'Invalid section name',
    addNewSection: 'Add new section',
  },
  project: {
    notLoaded: 'The project not loaded correctly  or not found.',
    noStaffAccount: 'This project has NO staff account',
  },
  forms: {
    placeHolders: { createNewSection: 'Create new section', sectionName: 'Section Name', articleName: 'Article Name' },
    messages: { valueToShort: 'The value you have entered is to short' },
  },
  search: {
    relevance: 'Relevans',
    alphabetical: 'Alphabetical',
    up: 'Rising',
    down: 'Falling',
    allResults: 'All results',
    seeAll: 'See all results for',
    searchSuggest: 'Search suggestions',
    searchError: 'You did not enter any search terms',
    pageNumberError: 'The number entered is incorrect',
    resultError: 'No article were found matching your selection.',
  },
  versionOutdated: {
    title: 'A new version is available',
    body: 'Some functionlity may break until the new version has been loaded',
    button: 'Load new version',
  },
};

export const sv: LocalizedStrings = {
  auth: {
    signInButton: 'Logga in',
    signOutButton: 'Logga ut',
    emailTitle: 'E-post',
    emailPlaceholder: 'example@domain.com',
    passwordTitle: 'Lösenord',
    passwordPlaceholder: 'Lösenord',
    passwordPlaceholderRetype: 'Upprepa lösenord',
    logout: 'Logga ut',
    error: {
      unknown: 'Okänt fel',
      invalidCredentials: 'Ogiltigt användarnamn eller lösenord',
      invalidStaffPassword: 'Ogiltigt lösenord för personalkonto',
    },
    wrongProject: 'Du har inte behörighet till det här projektet.',
    staffAccount: 'Personal',
    adminAccount: 'Administratör',
    authenticated: 'Du är inloggad som',
    loggedout: 'Du har loggats ut',
    notAuthenticated: 'Du är inte inloggad',
    notAuthorized: 'Du har inte rättigheter till det här',
    passwordChangeSuccess: 'Lösenordet uppdaterat',
    passwordChangeFailure: 'Lösenordsuppdateringen misslyckades!',
    invalidPasswordFormat: 'Lösenordet är inte korrekt formaterat',
    invalidPasswordRetype: 'Lösenorden matchar inte',
    staffPasswordNotUnique: 'Lösenord för personalkonton måste vara unika',
    welcomeBack: 'Välkommen tillbaka',
    loginToGetAccess: 'Logga in för att få tillgång till plattformen',
    forgotPasswordClick: 'Glömt ditt lösenord? Klicka',
    here: 'här',
    resetEmailSent: 'Email skickat!',
    resetEmailFail: 'Fel vid emailutskick. Kontakta support.',
    recoveryEmailInfo:
      'Skriv in e-postadressen du använder för att logga in på plattformen. Därefter får du ett mail för att återställa ditt lösenord. Om mailet inte kommer eller du har andra frågor, kontakta Softwerks support',
    resetPassword: 'Återställ lösenord',
    loginToSee: 'Logga in för att få tillgång till innehållet',
    passwordRules: {
      adminHeader: 'Lösenord Administratörskonto',
      staffHeader: 'Lösenord Personalkonto',
      lowercase: 'Minst en liten bokstav',
      uppercase: 'Minst en stor bokstav',
      special: 'Minst ett specialtecken [?,!,$,...]',
      number: 'Minst ett nummer',
      whitespace: 'Inget vitt utrymme',
      adminMin: 'Minst 8 tecken långt',
      staffMin: 'Minst 6 tecken',
      staffMax: 'Max 20 tecken',
    },
  },
  error: {
    error: 'Fel',
    unknown: 'Något gick snett!',
    404: 'Innehållet hittas inte',
  },
  external: {
    externalContent: 'Externt Innehåll',
  },
  adminViewMode: {
    enterPublishMode: 'Se publicerat material',
    onlyPublishedMaterialIsShown: 'endast material som publicerats visas',
    editTip:
      'Ändringar ni sparat i redigeringsläget kan bara ses av administratörer. Endast efter att ni publicerat så kommer era senaste ändringar att bli synliga för icke-administratörer.',
    publishTip:
      'I publiceringsläget så kan ni se det som redan har blivit publicerat. Versionen av kategorier, sektioner, och artiklar är densamma som vad en vanlig användare kan se. Ni kan inte göra ändringar i detta visningsläge.',
  },
  global: {
    save: 'Spara',
    doNotSave: 'Spara inte',
    cancel: 'Avbryt',
    edit: 'Redigera',
    view: 'Visa',
    preview: 'Förhandsvisa',
    delete: 'Ta bort',
    loading: 'Laddar...',
    startApplication: 'Startar Applikation...',
    searchResult: 'sökresultat',
    search: 'Sök',
    allResults: 'Alla resultat för',
    feedbackSubject: 'Ny feedback skickad',
    privacyPolicy: 'Integritetspolicy',
    termsOfUse: 'Juridiska dokument',
    fileFromatError: 'Filformat stöds inte',
    print: 'Skriv ut',
    create: 'Skapa',
    clear: 'Rensa',
    ok: 'OK',
    send: 'Skicka',
    online: 'Du är online',
    offline: 'Du är offline. Viss funktionalitet är begränsad.',
    clickToReload: 'Ladda om komponent',
    clickToReloadFull: 'Ladda om sidan',
    linkTo: 'Länk till',
    linkCopied: 'Länken har kopierats!',
    insertAnchorlink: 'Infoga ankarlänk',
    homePage: 'Hemsida',
    fullscreen: 'Fullskärm',
    feedback: {
      feedback: 'Feedback',
      feedbackAndPrint: 'Feedback & Skriv ut',
      send: 'Skicka feedback',
      noFeedbackEmailExist: 'Ingen feedback-e-post är registrerad',
    },
    addurl: 'Bädda in externt innehåll',
    iframTooltip: 'Du kan ange ditt värde i pixlar eller procent. Skriv % eller px efter värdet. Standardvärde:',
    youAreIn: 'Du är i',
    selectAll: 'Välj alla',
  },
  publish: {
    publish: 'Publicera',
    publishing: 'Publicerar',
    start: 'Publicering påbörjad!',
    publishOk: 'Publicering klar!',
    publishFail: 'Publicering misslyckades',
    publishMode: 'Publiceringsläge',
    editMode: 'Redigeringsläge',
    defaultPublishMessage: 'Nytt innehåll har publicerats',
    setPublishMessage: 'Publiceringsmeddelande',
    publishWithNotification: 'Publicera med notis',
    linksWithIssues: 'Länkar med problem',
    imagesWithIssues: 'Bilder med problem',
    titles: {
      lastUpdatedDate: 'Datum',
      lastUpdated: 'Senast ändrad',
      lastUpdatedBy: 'Senast ändrad av',
      name: 'Artikel',
      state: 'Status',
    },
    editStates: {
      created: 'Tillagd',
      deleted: 'Borttagen',
      updated: 'Uppdaterad',
    },
    help: {
      title: 'Hjälp',
      whatIsIncludedWhenPublishing: {
        title: 'Vad finns med i en publicering?',
        articles:
          'I listan på denna sida syns alla artiklar som innehåller ändringar jämfört med det som redan är publicerat. Endast de artiklar som blivit avbockade i listan kommer att publiceras. Oavsett vilka artiklar som är valda (och även om inga artiklar är valda) så kommer ändringar av följande ALLTID finnas med i publiceringen:',
        alwaysIncludedList: [
          'Låst/upplåst-status av vanliga kategorier',
          'Projektkonfiguration',
          'Sektioner',
          'Artikelordning i sektioner',
        ],
        lastUpdatedBy: 'Namnet på Admin som senast redigerat en artikel är aldrig synligt för icke-Admins.',
      },
      sidebarSettings: {
        title: 'Publiceringsinställningar',
        publishMessage: 'Publiceringsmeddelandet finns med i ändringsloggen, och är den text som visas i pushnotisen.',
        sendNotification:
          '"Publicera med notis" innebär att en push-notis skickas till alla mobiler och surfplattor som har appen installerad.',
      },
    },

    noArticlesHaveBeenEdited: 'Inga artiklar har redigerats, lagts till, eller tagits bort sedan senaste publicering!',
    noStaffAccountsExist: 'Det finns inga personalkonton att notifiera!',
    atClock: 'kl',
    publishMessagePlaceholder: 'Publiceringsmeddelande',
    errorLoadingAccounts: 'Kunde inte ladda användarkonton!',
    notificationSettings: 'Notis-inställningar',
    notifyAll: 'Meddela alla användare',
    chooseTargets: 'Välj användare att meddela',
  },
  settings: {
    settings: 'Inställningar',
    email: 'E-post-adresser för feedback',
    emailSettings: 'E-post inställningar',
    addEmailsHint: 'Lägg till e-post som feedback-mail',
    categoryAccessHint: 'Åtkomst för låsta kategorier måste ges per konto. Upplåsta kategorier kan ses av allmänheten.',
    updateOk: 'Ändringen har sparats',
    updateFailed: 'Ändringen misslyckades',
    emailAlreadyExist: 'E-postadressen finns redan',
    staffAccountMng: 'Personalkonton',
    staffAccountCategories: 'Ändra åtkomst till låsta kategorier',
    staffAccountCategoriesPlaceholder: 'Alla kategorier är upplåsta!',
    accountMng: 'Ditt konto',
    selectAccount: 'Välj ett konto att redigera',
    emptyAccountSearch: 'Inga resultat!',
    changeAdminPass: 'Ändra lösenord för administratörkonto',
    changeStaffPass: 'Ändra lösenord för personalkonto',
    passChangeSuccess: 'Lösenordet har ändrats!',
    passChangeFail: 'Byte av lösenord misslyckades',
    emailError: 'Fel i e-postadressen',
    export: {
      create: 'Skapa ny export',
      download: 'Ladda ner export',
      delete: 'Radera export',
      deleteFinished: 'Export raderad',
      exportStarted: 'Påbörjar export...',
      exportFinished: 'Exporten har skapats!',
      generating: 'Skapar export...',
      hint:
        'Exportera den senaste publicerade versionen av din webb-app som går att använda helt offline. Senaste exporten lagras och kan laddas ner flera gånger.',
      title: 'Exportera',
      notFound: 'Ingen export har skapats ännu',
      failedToDownload: 'Nedladdningen av zip-filen misslyckades!',
    },
    projectSettings: 'Namnval',
    projectNameHint: 'Applikationens namn',
    projectPublisherHint: 'Innehålls- och publiceringsansvarig',
    projectOrganisationHint: 'Organisation',
    handbook: 'Handbok',
    categories: 'Kategorier',
    accessRoles: 'Åtkomstroller',
    access: 'Hantera åtkomst till kategorier',
    selectLanguage: 'Välj språk',
  },
  sorting: {
    articleSort: 'Artiklar',
    sectionSort: 'Sektioner',
    titleForm: 'Artiklar&Sektioner sortering',
    articleEdit: 'Redigera artiklar',
    sectionEdit: 'Redigera sektioner',
    cancelSorting: 'Avbryt',
    saveSorting: 'Spara',
    sortingArticlesHasChanged: 'Sortering har ändrats!',
    sortingSectionsHasChanged: 'Sortering har ändrats!',
    unsavedChanges: 'Du har osparade ändringar i sorteringen',
    sortingHasChanged: 'Sortering har ändrats',
  },
  article: {
    article: 'Artikel',
    draftModalHeader: 'Innehåller osparat innehåll',
    draftModalBody: 'Redigerat innehåll har inte sparats.',
    articleSavedOk: 'Artikeln sparad',
    deleteModalHeader: 'Radera artikel',
    deleteModalBody: 'Vill du verkligen radera artikeln?',
    deleteModalBodyPerma: 'Vill du verkligen radera artikeln permanent?',
    deleteOk: 'Artikel raderad',
    addOk: 'Artikel har lagts till',
    sortingSaved: 'Artikelsortering sparad',
    addNewArticle: ' Artikel',
    wrongArticleName: 'Artikelnamnet är inte giltigt',
    notFoundWarning: 'Artikeln hittades inte!',
    updateOk: 'Artikeln har uppdaterats',
    updateFailed: 'Artikeluppdateringen misslyckades',
    fileTooLarge: 'Filen är för stor. Max 10MB',
    protectedArticle: {
      errorMessage: 'För att kunna se denna sida måste du vara inloggad.',
      errorMessageStaff: 'För att kunna se denna sida måste du ha tillstånd.',
    },
  },
  category: {
    category: 'Kategori',
    lock: 'Pin lock',
    lockCategoryInputHint:
      'Set pin code to protect the category, pin should be numbers between 4 and 6 long.' +
      'to remove the pin provide empty value',
    lockOk: 'Category pin has been set.',
    lockFail: 'Error while setting category pin.',
    login: 'View Content',
    password: 'Enter pin code',
    pinCheckNotValid: 'Pin code is not valid!',
    pinAddNotValid:
      'Provided pin is not valid should be between 4 -6 and only numbers. To remove the pin provide empty value',
    protectOk: 'Behörighet för kategori uppdaterad',
    protectFail: 'uppdatering av kategoribehörighet, misslyckades',
    notFoundWarning: 'Kategori hittades inte!',
    updated: 'Kategori uppdaterad',
    updateError: 'Fel i kategoriuppdatering',
    protectedCategory: {
      chiefErrorMessage: 'För att kunna se dessa artiklar måste du vara administratör.',
      defaultErrorMessage: 'För att kunna se dessa artiklar måste du ha tillstånd.',
    },
  },
  section: {
    section: 'Sektion',
    defaultSection: 'Ingen sektion',
    deleteModalHeader: 'Radera Sektion',
    deleteModalBody: 'Vill du verkligen radera sektionen?',
    deleteModalBodyPerma: 'Vill du verkligen radera sektionen permanent?',
    sectionDeleted: 'Sektionen raderad',
    sortingSaved: 'Sektionssortering sparad',
    addOk: 'Sektion har lagts till',
    addError: 'Kunde inte lägga till sektion',
    deleteOk: 'Sektion raderad',
    addNewSection: ' Sektion',
    wrongSectionName: 'Sektionsnamnet är inte giltigt',
  },
  project: {
    notLoaded: 'Projektet laddades inte in korrekt eller hittades inte',
    noStaffAccount: 'Projektet här ingen personalkonto',
  },
  forms: {
    placeHolders: { createNewSection: 'Lägg till ny sektion', sectionName: 'Sektionsnamn', articleName: 'Artikelnamn' },
    messages: { valueToShort: 'The value you have entered is to short' },
  },
  search: {
    relevance: 'Relevans',
    alphabetical: 'Alfabetiskt',
    up: 'Stigande',
    down: 'Fallande',
    allResults: 'Alla resultat',
    seeAll: 'Se alla resultat för',
    searchSuggest: 'Sökförslag',
    searchError: 'Du har inte angett några söktermer',
    pageNumberError: 'Det angivna numret är felaktigt',
    resultError: 'Ingen artikel hittades som matchade ditt val.',
  },
  versionOutdated: {
    title: 'En ny version av är tillgänglig.',
    body: 'Viss funktionalitet kan sluta fungera tills den nya versionen laddats in.',
    button: 'Ladda ny version',
  },
};

export const lv: LocalizedStrings = {
  auth: {
    signInButton: 'Pierakstīties',
    signOutButton: 'Izrakstīties',
    emailTitle: 'E-pasts',
    emailPlaceholder: 'jūsu@epasts.lv',
    passwordTitle: 'Parole',
    passwordPlaceholder: 'Parole',
    passwordPlaceholderRetype: 'Atkārtoti ievadiet paroli',
    logout: 'Iziet',
    error: {
      unknown: 'Nezināma kļūda',
      invalidCredentials: 'Nederīgs e-pasts/parole',
      invalidStaffPassword: 'Nederīga darbinieka konta parole',
    },
    wrongProject: 'Jūs nevarat pieslēgties šim projektam!',
    staffAccount: 'Personāls',
    adminAccount: 'Administrators',
    authenticated: 'Jūs esat pieslēdzies kā',
    loggedout: 'Jūs esat izgājis',
    notAuthenticated: 'Jūs neesat pieslēdzies',
    notAuthorized: 'Jūs neesat pilnvarots',
    passwordChangeFailure: 'Neizdevās atjaunināt paroli',
    passwordChangeSuccess: 'Parole atjaunināta',
    invalidPasswordFormat: 'Paroles formāts ir nepareizs!',
    invalidPasswordRetype: 'Paroles nesakrīt!',
    staffPasswordNotUnique: 'Personāla konta parolei jābūt unikālai',
    welcomeBack: 'Laipni lūdzam atpakaļ',
    loginToGetAccess: 'Pieslēdzieties, lai piekļūtu platformai',
    forgotPasswordClick: 'Aizmirsāt paroli?” Klikšķiniet',
    here: 'šeit',
    resetEmailSent: 'E-pasta vēstule ir nosūtīta. Pārbaudiet savu iesūtni',
    resetEmailFail: 'Nezināma problēma. E-pasts nav nosūtīts, sazinieties ar atbalsta dienestu.',
    recoveryEmailInfo:
      'Ievadiet savu e-pasta adresi, kuru izmantojat, lai pierakstītos platformā, un saņemiet paroles atjaunošanas e-pastu. Ja nesaņemat e-pasta vēstuli vai ja jums ir citi jautājumi, sazinieties ar Softwerk atbalsta dienestu',
    resetPassword: 'Atjaunot paroli',
    loginToSee: 'Pieslēdzieties, lai piekļūtu saturam',
    passwordRules: {
      adminHeader: 'Administratora konta parole',
      staffHeader: 'Personāla konta parole',
      lowercase: 'Vismaz viens mazais burts',
      uppercase: 'Vismaz viens lielais burts',
      special: 'Vismaz viens īpašais simbols [?,!,$,...]',
      number: 'Vismaz viens skaitlis',
      whitespace: 'Bez atstarpēm',
      adminMin: 'Vismaz 8 rakstzīmes',
      staffMin: 'Vismaz 6 rakstzīmes',
      staffMax: 'Ne vairāk kā 20 rakstzīmju',
    },
  },
  error: {
    error: 'Kļūda',
    unknown: 'Kaut kas notika nepareizi!!',
    404: 'Saturs nav atrasts',
  },
  external: {
    externalContent: 'Ārējais saturs',
  },
  adminViewMode: {
    enterPublishMode: 'Skatīt publicēto saturu',
    onlyPublishedMaterialIsShown: 'tiek rādīts tikai publicētais saturs',
    editTip:
      'Šajā režīmā veiktās izmaiņas var skatīt tikai administratori. Jaunās izmaiņas lietotājiem, kas nav administratori, būs redzamas tikai pēc publicēšanas.',
    publishTip:
      'Šajā režīmā varat skatīt tikai publicētus materiālus. Redzamās versiju kategorijas, sadaļas un raksti ir tādi paši, kādus redzētu lietotājs, kas nav administrators. Šajā režīmā nevarat veikt nekādas izmaiņas.',
  },
  global: {
    save: 'Saglabāt',
    doNotSave: 'Nesaglabāt',
    cancel: 'Atcelt',
    edit: 'Rediģēt',
    preview: 'Priekšskatīt',
    view: 'Skatīt',
    delete: 'Dzēst',
    loading: 'Notiek ielāde...',
    startApplication: 'Sākt lietojumprogrammu…',
    searchResult: 'Meklēšanas rezultāts',
    search: 'Meklēt',
    allResults: 'Visi rezultāti par',
    feedbackSubject: 'Iesniegta jauna atsauksme par rakstu”',
    privacyPolicy: 'Privātuma politika',
    termsOfUse: 'Juridiskie dokumenti',
    fileFromatError: 'Datnes paplašinājums nav atbalstīts',
    print: 'Drukāt',
    create: 'Izveidot',
    clear: 'Notīrīt',
    ok: 'Labi',
    send: 'Sūtīt',
    online: 'Jūs esat tiešsaistē',
    offline: 'Jūs esat bezsaistē. Dažas funkcijas ir ierobežotas.',
    clickToReload: 'Pārlādēt sadaļu',
    clickToReloadFull: 'Pārlādēt lapu',
    linkTo: 'Saite uz',
    linkCopied: 'Saite ir nokopēta jūsu starpliktuvē',
    insertAnchorlink: 'Ievietot enkura saiti',
    homePage: 'Sākuma lapa',
    fullscreen: 'Pilna ekrāna',
    feedback: {
      feedback: 'Atsauksmes',
      feedbackAndPrint: 'Atsauksmes un drukāšana',
      send: 'Sūtīt atsauksmes',
      noFeedbackEmailExist: 'Nav reģistrēts atgriezeniskās saites e-pasts',
    },
    addurl: 'Iekļaut ārējo saturu',
    iframTooltip: 'Vērtību var ievadīt pikseļos vai procentuāli. Lūdzu, aiz vērtības ierakstiet % vai px.',
    youAreIn: 'Jūs esat pieslēdzies',
    selectAll: 'Atlasīt visus',
  },
  publish: {
    publish: 'Publicēt',
    publishing: 'Publicēšana',
    start: 'Publicēšanas process ir sākts',
    publishOk: 'Publicēšana pabeigta',
    publishFail: 'Publicēšana neizdevās',
    publishMode: 'Atbrīvots',
    editMode: 'Rediģēšanas režīms',
    defaultPublishMessage: 'Ir publicēts jauns saturs.',
    setPublishMessage: 'Iestatīt publicēšanas ziņojumu',
    publishWithNotification: 'Publicēt ar paziņojumu',
    linksWithIssues: 'Saites ar problēmām',
    imagesWithIssues: 'Attēli ar problēmām',
    help: {
      title: 'Palīdzība',
      whatIsIncludedWhenPublishing: {
        title: 'Kas ir iekļauts publikācijā?',
        articles:
          'Sarakstā pa labi ir norādīti visi raksti, kuros ir izmaiņas (salīdzinājumā ar pašlaik publicēto materiālu). Tiks publicēti tikai tie raksti, kuru izvēles rūtiņa ir atzīmēta',
        alwaysIncludedList: [
          'Bloķēts/atbloķēts statuss vai parastās kategorijas',
          'Projekta konfigurācija',
          'Sadaļas',
          'Rakstu sakārtošana sadaļās',
        ],
        lastUpdatedBy:
          'Tā administratora vārds, kurš pēdējo reizi rediģēja rakstu, nekad nav redzams personām, kas nav administratori.',
      },
      sidebarSettings: {
        title: 'Publicēt iestatījumus',
        publishMessage:
          'Publicēšanas ziņojums ir reģistrēts izmaiņu žurnālā, un sistēmā par to tiek parādīts paziņojums.',
        sendNotification:
          '”Publicēt ar paziņojumu” nozīmē, ka uz visām mobilajām ierīcēm, kurās ir instalēta lietotne, tiks nosūtīts sistēmas paziņojums..',
      },
    },
    titles: {
      lastUpdatedDate: 'Datums',
      lastUpdatedBy: 'Pēdējo atjauninājumu veica',
      lastUpdated: 'Pēdējo reizi atjaunināts',
      name: 'Raksts',
      state: 'Statuss',
    },
    editStates: {
      created: 'Pievienots',
      deleted: 'Dzēsts',
      updated: 'Atjaunināts',
    },
    noArticlesHaveBeenEdited: 'Kopš pēdējās publicēšanas neviens raksts nav rediģēts, pievienots vai dzēsts!',
    noStaffAccountsExist: 'Nav personāla kontu, kurus informēt!',
    atClock: 'pulksten',
    publishMessagePlaceholder: 'Publicēt ziņu',
    errorLoadingAccounts: 'Nevar ielādēt kontus!',
    notificationSettings: 'Paziņojumu iestatījumi',
    notifyAll: 'Paziņot visiem lietotājiem',
    chooseTargets: 'Izvēlieties kontus, kuriem paziņot',
  },
  settings: {
    settings: 'Iestatījumi',
    email: 'Atsauksmju e-pasta adreses',
    emailSettings: 'E-pasta iestatījumi',
    addEmailsHint: 'Pievienot e-pasta ziņojumus atsauksmju e-pastiem.',
    categoryAccessHint:
      'Piekļuve bloķētām kategorijām ir jānodrošina katram kontam atsevišķi. Atbloķētajām kategorijām var piekļūt ikviens.',
    updateOk: 'Izmaiņas ir saglabātas',
    updateFailed: 'Neizdevās saglabāt izmaiņas',
    emailAlreadyExist: 'E-pasts jau pastāv',
    staffAccountMng: 'Personāla konti',
    staffAccountCategories: 'Mainīt piekļuvi bloķētajām kategorijām',
    staffAccountCategoriesPlaceholder: 'Visas kategorijas ir atbloķētas!',
    accountMng: 'Kontu pārvaldība',
    selectAccount: 'Izvēlieties kontu rediģēšanai',
    emptyAccountSearch: 'Nav rezultātu!',
    changeAdminPass: 'Mainīt administratora konta paroli',
    changeStaffPass: 'Mainīt personāla konta paroli',
    passChangeSuccess: 'Paroles maiņa izdevās!',
    passChangeFail: 'Mainīt paroli neizdevās',
    emailError: 'Kļūda e-pastā!',
    export: {
      create: 'Izveidot jaunu eksportu',
      delete: 'Dzēst eksportu',
      deleteFinished: 'Eksports dzēsts',
      download: 'Lejupielādēt eksportu',
      exportFinished: 'Eksports ir ģenerēts!',
      exportStarted: 'Uzsāk eksportēšanu...',
      generating: 'Eksporta ģenerēšana...',
      hint:
        'Eksportēt tikai bezsaistes režīmā pieejamu tīmekļa lietojumprogrammas versiju. Jaunākais eksports tiek saglabāts, un to var lejupielādēt vairākas reizes.',
      title: 'Eksportēt',
      notFound: 'Eksports vēl nav veikts',
      failedToDownload: 'Neizdevās lejupielādēt zip failu!',
    },
    projectSettings: 'Projekta iestatījumi',
    projectNameHint: 'Nosaukums',
    projectOrganisationHint: 'Organizācija',
    projectPublisherHint: 'Izdevējs',
    handbook: 'Rokasgrāmata',
    categories: 'Kategorijas',
    accessRoles: 'Piekļuves lomas',
    access: 'Pārvaldīt piekļuvi kategorijām',
    selectLanguage: 'Izvēlieties valodu',
  },
  sorting: {
    articleSort: 'Raksti',
    sectionSort: 'Sadaļas',
    titleForm: 'Rakstu un sadaļu kārtošana',
    articleEdit: 'Rediģēt rakstus',
    sectionEdit: 'Rediģēt sadaļas',
    cancelSorting: 'Atcelt kārtošanu',
    saveSorting: 'Saglabāt kārtošanu',
    sortingArticlesHasChanged: 'Kārtošana ir mainīta',
    sortingSectionsHasChanged: 'Kārtošana ir mainīta',
    unsavedChanges: 'Jums ir nesaglabātas izmaiņas',
    sortingHasChanged: 'Kārtošana ir mainīta',
  },
  article: {
    article: 'Raksts',
    draftModalHeader: 'Saturs nav saglabāts',
    draftModalBody: 'Rediģētais saturs nav saglabāts.',
    articleSavedOk: 'Raksts ir saglabāts',
    deleteModalHeader: 'Dzēst rakstu',
    deleteModalBody: 'Vai esat pārliecināts, ka vēlaties izdzēst šo rakstu?',
    deleteModalBodyPerma: 'Vai esat pārliecināts, ka vēlaties neatgriezeniski izdzēst šo rakstu?',
    deleteOk: 'Raksts ir izdzēsts!',
    addOk: 'Raksts ir pievienots!',
    sortingSaved: 'Rakstu kārtošana ir saglabāta',
    addNewArticle: 'Pievienot jaunu rakstu',
    wrongArticleName: 'Nepareizs raksta nosaukums',
    notFoundWarning: 'Raksts nav atrasts!',
    updateOk: 'Raksts ir atjaunināts',
    updateFailed: 'Raksta atjaunināšana neizdevās',
    fileTooLarge: 'Datne ir pārāk liela. Maks. 10 MB',
    protectedArticle: {
      errorMessage: 'Jums jāpierakstās, lai skatītu šo rakstu.',
      errorMessageStaff: 'Jums jābūt piešķirtai piekļuvei, lai skatītu šo rakstu.',
    },
  },
  category: {
    category: 'Kategorija',
    lock: 'Pin bloķētājs',
    lockCategoryInputHint:
      'Iestatiet PIN kodu, lai aizsargātu kategoriju. PIN koda garumam jābūt no 4 līdz 6 cipariem' +
      'lai noņemtu PIN, norādiet tukšu vērtību',
    lockOk: 'Ir iestatīts kategorijas PIN.',
    lockFail: 'Kļūda, iestatot kategorijas PIN kodu.',
    login: 'Skatīt saturu',
    password: 'Ievadiet PIN kodu',
    pinCheckNotValid: 'PIN kods nav derīgs!',
    pinAddNotValid:
      'Iesniegtais PIN nav derīgs — tam jābūt starp 4-6 skaitļiem garam.  Lai noņemtu PIN kodu, norādiet tukšu vērtību',
    protectOk: 'Kategorijas aizsardzība ir mainīta',
    protectFail: 'Nomainīt kategoriju aizsardzību neizdevās',
    notFoundWarning: 'Kategorija nav atrasta!',
    updated: 'Kategorija atjaunināta',
    updateError: 'Kļūda, atjauninot kategoriju',
    protectedCategory: {
      chiefErrorMessage: 'Jums jābūt administratoram, lai skatītu šos rakstus.',
      defaultErrorMessage: 'Jums jābūt piešķirtai piekļuvei, lai skatītu šos rakstus.',
    },
  },
  section: {
    section: 'Sadaļa',
    defaultSection: 'Nav sadaļas',
    deleteModalHeader: 'Dzēst sadaļu',
    deleteModalBody: 'Vai esat pārliecināts, ka vēlaties dzēst šo sadaļu?',
    deleteModalBodyPerma: 'Vai esat pārliecināts, ka vēlaties šo sadaļu dzēst uz visiem laikiem?',
    sectionDeleted: 'Sadaļa ir dzēsta!',
    sortingSaved: 'Saglabāta sadaļu kārtošana',
    addOk: 'Pievienota sadaļa',
    addError: 'Sadaļas pievienošana neizdevās',
    deleteOk: 'Sadaļa ir dzēsta',
    wrongSectionName: 'Nederīgs sadaļas nosaukums',
    addNewSection: 'Pievienot jaunu sadaļu',
  },
  project: {
    notLoaded: 'Projekts nav pareizi ielādēts vai nav atrasts.',
    noStaffAccount: 'Šim projektam NAV personāla konta',
  },
  forms: {
    placeHolders: {
      createNewSection: 'Izveidot jaunu sadaļu',
      sectionName: 'Sadaļas nosaukums',
      articleName: 'Raksta nosaukums',
    },
    messages: { valueToShort: 'Ievadītā vērtība ir pārāk īsa' },
  },
  search: {
    relevance: 'Atbilstība',
    alphabetical: 'Pēc alfabēta',
    up: 'Augoši',
    down: 'Dilstoši',
    allResults: 'Visi rezultāti',
    seeAll: 'Skatīt visus rezultātus par',
    searchSuggest: 'Meklēšanas ieteikumi',
    searchError: 'Jūs neievadījāt nekādus meklēšanas noteikumus',
    pageNumberError: 'Ievadītais numurs ir nepareizs',
    resultError: 'Netika atrasts neviens raksts, kas atbilstu jūsu atlasei..',
  },
  versionOutdated: {
    title: 'Ir pieejama jauna versija',
    body: 'Dažas funkcijas var nedarboties, kamēr nav ielādēta jaunā versija',
    button: 'Ielādēt jauno versiju',
  },
};

/**
 * Localization implemented as a class that just reads from localstorage to support our custom
 * Froala components. They don't work with hooks because they aren't React components.
 * It's a bit messy to have two sort-of parallell localization systems, but it works for now.
 * (See useLanguage in LocalizationContext for regular localization implementation)
 */
export class StaticLocalization {
  strings: LocalizedStrings;

  constructor() {
    let tmpLang: string;
    try {
      tmpLang = JSON.parse(localStorage.getItem('language') || '');
    } catch (error) {
      tmpLang = LanguageCode.Svenska;
    }
    this.setLanguage(tmpLang as LanguageCode);
  }

  private setLanguage = (lang: LanguageCode) => {
    switch (lang) {
      case LanguageCode.English:
        this.strings = en;
        break;
      case LanguageCode.Latviešu:
        this.strings = lv;
        break;
      default:
        this.strings = sv;
        break;
    }
  };
}

export const staticLocalization = new StaticLocalization();
