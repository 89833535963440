import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useArticles, useCategories, useExternals, usePlatform } from '../ContextProviders/AppContext';
import './PlatformContentFrame.scss';
import { useLocation } from 'react-router-dom';
import { toasts } from '../../shared';
import { useLocalization } from '../ContextProviders/LocalizationContext';
import { useAuth } from '../ContextProviders/Auth';
import { LinkTypes, checkHyperLinkType } from '../Editor/LinkTypeChecker';
import { isValidUrl } from '../../util/DataHelpers';

interface IFrameData {
  body: string;
  scrolling?: string;
  header?: boolean;
  bodyClass?: string;
  canBeUrl?: boolean;
}
const template = (
  scriptSrcs: string[],
  styleHrefs: string[],
  body: string,
  injectHeader: boolean,
  bodyClass?: string,
): string => `
<!DOCTYPE HTML>
<html>
<head>
  <base target="_top">
  ${scriptSrcs.map((src) => `<script type="text/javascript" src="${src}"></script>`).join('\n')}
  ${styleHrefs.map((src) => `<link rel="stylesheet" type="text/css" href="${src}">`).join('\n')}
  <style>
  body:not(.edit-mode) [data-anchor-link] {
    visibility:hidden;
    width:0;
    pointer-events:none;
    overflow:hidden;
    display:inline-block;
  }
  [data-anchor-link] {
    cursor: pointer;
  }
  </style>
</head>
<body class="fr-view ${bodyClass ?? ''}">
    ${body}
    ${
      injectHeader
        ? `<script type="text/javascript">
    if (typeof setupArticle === "function") {
      setupArticle();
    }
    </script>`
        : ''
    }
</body>
</html>
`;

export const PlatformContentFrame = ({
  body,
  scrolling = 'yes',
  header = true,
  bodyClass,
  canBeUrl,
}: IFrameData): ReactElement => {
  const localization = useLocalization();
  const platform = usePlatform();
  const { projectJS, projectCSS, platformCSS, platformJS, froalaCSS } = platform.doc;
  const [element, setElement] = useState<HTMLIFrameElement>();
  const history = useHistory();
  const location = useLocation();

  const { docs: externals } = useExternals();
  const { docs: categories } = useCategories();
  const { docs: articles } = useArticles();
  const auth = useAuth();

  const linkCallback = useCallback(
    (ev: Event) => {
      const target = ev.target as HTMLElement;

      const anchorAttribute = target.getAttribute('data-anchor-link');
      if (anchorAttribute) {
        void navigator.clipboard.writeText(anchorAttribute).then(() => {
          toasts.info(localization.strings.global.linkCopied);
        });
      }

      const link = target.closest('a')?.getAttributeNS(null, 'href');
      if (!link) return;
      ev.preventDefault();

      const url = new URL(link, window.origin);

      // scroll to hash on current page
      const locationId = url.pathname.substring(url.pathname.lastIndexOf('/') + 1);
      if (url.hash && document.location.pathname.includes(locationId)) {
        scrollToAnchorLink(url.hash);
        return;
      }

      if (document.location.hostname === url.hostname) {
        const linkURL = url.hash ? url.pathname + url.hash : url.pathname;
        if (onInterceptInternalLink(url)) {
          history.push(linkURL);
        }
      } else {
        window.open(url.href);
      }
    },
    // eslint-disable-next-line
    [externals, categories, auth, articles],
  );

  const onInterceptInternalLink = (url: URL): boolean => {
    const theLinkType = checkHyperLinkType(url);
    const internalLinkID = url.pathname.substring(url.pathname.lastIndexOf('/') + 1);

    // Don't bother checking permissions for public static pages
    switch (theLinkType) {
      case LinkTypes.internalPolicy:
        return true;
      case LinkTypes.internalTerms:
        return true;
      case LinkTypes.internalAbout:
        return true;
    }

    // Check externals permission
    if (theLinkType === LinkTypes.internalExternal) {
      const canAccessExternal = externals.find((external) => external.fId === internalLinkID);

      if (!canAccessExternal && !auth.isStaff) {
        toasts.warning(localization.strings.article.protectedArticle.errorMessage);
        return false;
      } else if (!canAccessExternal && auth.isStaff) {
        toasts.warning(localization.strings.article.protectedArticle.errorMessageStaff);
        return false;
      } else {
        return true;
      }
    }

    // Check user permissions - Category link
    if (theLinkType === LinkTypes.internalCategory || theLinkType === LinkTypes.internalChiefCategory) {
      if (theLinkType === LinkTypes.internalChiefCategory && !auth.isAdmin) {
        toasts.warning(localization.strings.category.protectedCategory.chiefErrorMessage);
        return false;
      }

      const canAccessCategory =
        !categories.find((category) => category.fId === internalLinkID)?.isProtected ||
        auth.categoryPermissions.includes(internalLinkID) ||
        auth.isAdmin;

      if (!canAccessCategory) {
        toasts.warning(localization.strings.category.protectedCategory.defaultErrorMessage);
        return false;
      } else {
        return true;
      }
    }

    // Check user permissons - Article Link
    const canAccessArticle =
      articles.find((article) => article.fId === internalLinkID) ||
      (theLinkType === LinkTypes.internalChangelogArticle && auth.categoryPermissions.includes('changelog')) ||
      auth.isAdmin;

    if (!canAccessArticle && !auth.isStaff) {
      toasts.warning(localization.strings.article.protectedArticle.errorMessage);
      return false;
    } else if (!canAccessArticle && auth.isStaff) {
      toasts.warning(localization.strings.article.protectedArticle.errorMessageStaff);
      return false;
    } else {
      return true;
    }
  };

  const scrollToAnchorLink = (targetId: string) => {
    const iframeContent = document.querySelector('iframe');
    const element = iframeContent?.contentDocument?.getElementById(targetId.replace('#', ''));

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (element) {
      if (document.readyState === 'complete') {
        element.contentDocument?.addEventListener('click', linkCallback, false);
      }
      element.onload = () => {
        element.contentDocument?.addEventListener('click', linkCallback, false);
        if (location.hash) {
          scrollToAnchorLink(location.hash);
        }
      };

      return () => {
        element.contentDocument?.removeEventListener('click', linkCallback, false);
      };
    }
    return () => {
      /**/
    };
    // eslint-disable-next-line
  }, [element, linkCallback]);

  if (!platform.doc) return <></>;

  return (
    <iframe
      title="platform-content-iframe"
      id="platform-content-iframe"
      ref={(r) => {
        if (r) setElement(r);
      }}
      scrolling={scrolling}
      {...(canBeUrl && isValidUrl(body)
        ? { src: body }
        : { srcDoc: template([platformJS, projectJS], [platformCSS, projectCSS, froalaCSS], body, header, bodyClass) })}
    ></iframe>
  );
};
