import { ReactElement } from 'react';
import './App.scss';
import { Layout } from '../Layout/Layout';
import { FirebaseProvider } from '../ContextProviders/Firebase';
import { StyleProvider } from '../ContextProviders/Style';
import { AppDataProvider } from '../ContextProviders/AppContext';
import { AuthProvider } from '../ContextProviders/Auth';
import { ProjectProvider } from '../ContextProviders/ProjectContext';
import 'react-toastify/dist/ReactToastify.css';
import { ModalContextProvider } from '../ContextProviders/ModalContext';
import { SearchProvider } from '../ContextProviders/SearchContext';
import { MonofunctionWarmer } from '../MonofunctionWarmer/MonofunctionWarmer';
import { reportError } from '../Error/Bugsnag';
import { CacheClearingErrorBoundary } from '../Error/CacheClearingErrorBoundary';
import { ErrorView } from '../Error/RootErrorView';
import { LocalizationProvider } from '../ContextProviders/LocalizationContext';

export const App = (): ReactElement => {
  return (
    <FirebaseProvider>
      <CacheClearingErrorBoundary
        onError={(e) => {
          reportError(e);
        }}
        FallbackComponent={ErrorView}
      >
        <ProjectProvider>
          <LocalizationProvider>
            <AuthProvider>
              <AppDataProvider>
                <StyleProvider>
                  <SearchProvider>
                    <ModalContextProvider>
                      <Layout />
                    </ModalContextProvider>
                  </SearchProvider>
                </StyleProvider>
              </AppDataProvider>
            </AuthProvider>
          </LocalizationProvider>
        </ProjectProvider>
        <MonofunctionWarmer />
      </CacheClearingErrorBoundary>
    </FirebaseProvider>
  );
};
