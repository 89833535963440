import { ReactElement, useEffect } from 'react';
import { useProjectConfig } from '../../ContextProviders/AppContext';
import { useAuth } from '../../ContextProviders/Auth';
import { Loading } from '../Loading/Loading';
import { PageWithSidebar } from '../PageTypes';
import ChangePasswordForm from './ChangePasswordForm';
import { SettingsSidebar } from './SettingsSidebar';
import { useLocalization } from '../../ContextProviders/LocalizationContext';

export const AccountManagement = (): ReactElement => {
  const localization = useLocalization();
  const auth = useAuth();
  const projectConfig = useProjectConfig();

  useEffect(() => {
    document.title = `${localization.strings.settings.accountMng} | ${projectConfig.doc.name}`;
  }, [projectConfig.doc.name, localization.strings.settings.accountMng]);

  if (projectConfig.loading) return <Loading waitingFor="Project Config" />;

  return (
    <PageWithSidebar>
      <SettingsSidebar />
      <main className="padded-container">
        <div className="settings-page">
          <h1>{localization.strings.settings.accountMng}</h1>
          <hr />
          <div className="acc-mng-module shadow-sm">
            <ChangePasswordForm
              label={localization.strings.settings.changeAdminPass}
              passType="ADMIN"
              userId={auth.user?.uid || ''}
            />
          </div>
        </div>
      </main>
    </PageWithSidebar>
  );
};
